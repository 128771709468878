import { SVGProps } from 'react'

export const CardanoLogo = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width='79'
    height='73'
    viewBox='0 0 79 73'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_39_6692)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.2176 20.3115C34.2728 20.3115 35.3043 20.6252 36.1817 21.2129C37.0591 21.8006 37.743 22.6359 38.1468 23.6132C38.5506 24.5905 38.6562 25.6659 38.4504 26.7034C38.2445 27.7409 37.7364 28.6939 36.9902 29.4419C36.2441 30.1899 35.2934 30.6993 34.2585 30.9057C33.2235 31.112 32.1508 31.0061 31.1759 30.6013C30.201 30.1965 29.3677 29.511 28.7815 28.6314C28.1952 27.7519 27.8823 26.7178 27.8823 25.66C27.8818 24.9574 28.0194 24.2617 28.2873 23.6126C28.5553 22.9634 28.9482 22.3736 29.4438 21.8768C29.9393 21.3801 30.5277 20.9861 31.1752 20.7175C31.8228 20.4489 32.5168 20.311 33.2176 20.3115Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.6941 20.2861C46.7494 20.2861 47.7809 20.5998 48.6583 21.1875C49.5357 21.7752 50.2195 22.6105 50.6233 23.5878C51.0271 24.5651 51.1328 25.6405 50.9269 26.678C50.7211 27.7155 50.2129 28.6685 49.4668 29.4165C48.7206 30.1645 47.77 30.6739 46.735 30.8803C45.7001 31.0866 44.6273 30.9807 43.6524 30.5759C42.6775 30.1711 41.8443 29.4856 41.258 28.606C40.6718 27.7265 40.3589 26.6924 40.3589 25.6346C40.3583 24.9321 40.496 24.2363 40.7639 23.5872C41.0318 22.938 41.4248 22.3482 41.9203 21.8514C42.4159 21.3547 43.0042 20.9607 43.6518 20.6921C44.2993 20.4235 44.9934 20.2856 45.6941 20.2861Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.0335 31.0967C53.0898 31.0958 54.1225 31.4091 55.0011 31.9967C55.8798 32.5844 56.5648 33.42 56.9696 34.398C57.3743 35.376 57.4807 36.4524 57.2751 37.491C57.0696 38.5296 56.5614 39.4837 55.8148 40.2328C55.0682 40.9818 54.1168 41.492 53.081 41.6988C52.0451 41.9057 50.9713 41.8 49.9954 41.395C49.0195 40.9899 48.1853 40.3039 47.5984 39.4236C47.0115 38.5432 46.6982 37.5082 46.6982 36.4493C46.6977 35.7466 46.8353 35.0507 47.1032 34.4013C47.371 33.7519 47.764 33.1618 48.2594 32.6647C48.7549 32.1676 49.3433 31.7733 49.9908 31.5042C50.6384 31.2352 51.3325 31.0967 52.0335 31.0967Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.7699 41.9873C46.8263 41.9865 47.8592 42.2998 48.7379 42.8876C49.6166 43.4755 50.3016 44.3114 50.7063 45.2896C51.1109 46.2679 51.217 47.3445 51.0112 48.3832C50.8053 49.4219 50.2966 50.376 49.5496 51.1249C48.8027 51.8737 47.8509 52.3836 46.8147 52.59C45.7786 52.7964 44.7046 52.69 43.7288 52.2844C42.7529 51.8787 41.9191 51.192 41.3327 50.3111C40.7463 49.4302 40.4337 48.3948 40.4346 47.3358C40.4346 45.9173 40.9967 44.5569 41.9972 43.5538C42.9978 42.5508 44.3549 41.9873 45.7699 41.9873Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.2469 42.0469C34.3033 42.046 35.3362 42.3594 36.2149 42.9472C37.0936 43.535 37.7787 44.371 38.1833 45.3492C38.588 46.3275 38.6941 47.404 38.4882 48.4428C38.2823 49.4815 37.7737 50.4356 37.0267 51.1844C36.2797 51.9333 35.3279 52.4432 34.2918 52.6496C33.2556 52.856 32.1817 52.7496 31.2058 52.3439C30.23 51.9383 29.3961 51.2516 28.8097 50.3707C28.2233 49.4898 27.9108 48.4543 27.9116 47.3953C27.9127 45.9772 28.4752 44.6174 29.4755 43.6146C30.4758 42.6118 31.8322 42.048 33.2469 42.0469Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.0125 31.1816C28.0689 31.1808 29.1019 31.4941 29.9806 32.082C30.8593 32.6698 31.5443 33.5057 31.949 34.484C32.3536 35.4622 32.4597 36.5388 32.2538 37.5775C32.0479 38.6162 31.5393 39.5704 30.7923 40.3192C30.0453 41.068 29.0935 41.5779 28.0574 41.7843C27.0212 41.9907 25.9473 41.8844 24.9715 41.4787C23.9956 41.0731 23.1618 40.3863 22.5754 39.5055C21.989 38.6246 21.6764 37.5891 21.6772 36.5301C21.6784 35.1119 22.2408 33.7522 23.2411 32.7494C24.2415 31.7466 25.5979 31.1828 27.0125 31.1816Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.9548 44.123C21.5746 44.123 22.1805 44.3073 22.6959 44.6525C23.2113 44.9977 23.613 45.4884 23.8502 46.0625C24.0874 46.6365 24.1494 47.2682 24.0285 47.8776C23.9076 48.4871 23.6091 49.0469 23.1708 49.4863C22.7325 49.9256 22.1741 50.2248 21.5662 50.3461C20.9582 50.4673 20.3281 50.4051 19.7554 50.1673C19.1828 49.9295 18.6933 49.5268 18.349 49.0102C18.0046 48.4935 17.8208 47.8861 17.8208 47.2647C17.8208 46.8522 17.9019 46.4436 18.0594 46.0625C18.2169 45.6813 18.4477 45.335 18.7387 45.0432C19.0297 44.7515 19.3752 44.5201 19.7554 44.3622C20.1357 44.2043 20.5432 44.123 20.9548 44.123Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.8877 22.6738C21.5073 22.6747 22.1128 22.8596 22.6277 23.2053C23.1425 23.5511 23.5435 24.042 23.7801 24.6162C24.0166 25.1903 24.0781 25.8219 23.9567 26.431C23.8353 27.0402 23.5365 27.5996 23.098 28.0385C22.6595 28.4775 22.1011 28.7763 21.4933 28.8971C20.8855 29.018 20.2556 28.9556 19.6832 28.7177C19.1108 28.4797 18.6216 28.0771 18.2774 27.5605C17.9332 27.044 17.7495 26.4367 17.7495 25.8155C17.7495 25.4026 17.8307 24.9937 17.9885 24.6123C18.1462 24.2308 18.3774 23.8843 18.6689 23.5925C18.9604 23.3007 19.3064 23.0694 19.6871 22.9118C20.0678 22.7541 20.4757 22.6733 20.8877 22.6738Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.4225 11.8809C40.0423 11.8809 40.6483 12.0651 41.1637 12.4103C41.679 12.7555 42.0807 13.2462 42.3179 13.8203C42.5551 14.3943 42.6172 15.026 42.4962 15.6355C42.3753 16.2449 42.0768 16.8047 41.6386 17.2441C41.2003 17.6834 40.6418 17.9826 40.0339 18.1039C39.426 18.2251 38.7959 18.1629 38.2232 17.9251C37.6506 17.6873 37.1611 17.2846 36.8167 16.768C36.4724 16.2513 36.2886 15.6439 36.2886 15.0225C36.2886 14.61 36.3696 14.2014 36.5271 13.8203C36.6846 13.4391 36.9155 13.0928 37.2065 12.801C37.4975 12.5093 37.843 12.2779 38.2232 12.12C38.6034 11.9621 39.011 11.8809 39.4225 11.8809Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.0329 22.5723C58.6527 22.5723 59.2586 22.7565 59.774 23.1017C60.2894 23.447 60.6911 23.9376 60.9283 24.5117C61.1655 25.0858 61.2275 25.7174 61.1066 26.3269C60.9857 26.9363 60.6872 27.4961 60.2489 27.9355C59.8106 28.3748 59.2522 28.674 58.6443 28.7953C58.0363 28.9165 57.4062 28.8543 56.8336 28.6165C56.2609 28.3787 55.7714 27.976 55.4271 27.4594C55.0827 26.9427 54.8989 26.3353 54.8989 25.714C54.8989 24.8807 55.2291 24.0816 55.8168 23.4924C56.4046 22.9033 57.2017 22.5723 58.0329 22.5723Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.0622 44.0635C58.682 44.0635 59.2879 44.2477 59.8033 44.593C60.3187 44.9382 60.7204 45.4288 60.9576 46.0029C61.1948 46.577 61.2569 47.2086 61.1359 47.8181C61.015 48.4275 60.7165 48.9873 60.2782 49.4267C59.8399 49.866 59.2815 50.1653 58.6736 50.2865C58.0656 50.4077 57.4355 50.3455 56.8629 50.1077C56.2902 49.8699 55.8007 49.4672 55.4564 48.9506C55.112 48.4339 54.9282 47.8265 54.9282 47.2052C54.9282 46.7926 55.0093 46.3841 55.1668 46.0029C55.3243 45.6217 55.5551 45.2754 55.8461 44.9837C56.1372 44.6919 56.4826 44.4605 56.8629 44.3026C57.2431 44.1447 57.6506 44.0635 58.0622 44.0635Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.3049 54.8281C39.9247 54.8281 40.5306 55.0124 41.046 55.3576C41.5614 55.7028 41.9631 56.1935 42.2003 56.7675C42.4375 57.3416 42.4995 57.9733 42.3786 58.5827C42.2577 59.1921 41.9592 59.7519 41.5209 60.1913C41.0826 60.6307 40.5242 60.9299 39.9163 61.0511C39.3083 61.1723 38.6782 61.1101 38.1055 60.8723C37.5329 60.6346 37.0434 60.2319 36.6991 59.7152C36.3547 59.1986 36.1709 58.5912 36.1709 57.9698C36.1709 57.1366 36.5011 56.3375 37.0888 55.7483C37.6765 55.1591 38.4737 54.8281 39.3049 54.8281Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.6515 58.0156C26.175 58.0156 26.6867 58.1712 27.1219 58.4628C27.5571 58.7543 27.8964 59.1687 28.0967 59.6535C28.297 60.1383 28.3494 60.6717 28.2473 61.1864C28.1452 61.7011 27.8931 62.1738 27.523 62.5449C27.1528 62.9159 26.6812 63.1686 26.1678 63.271C25.6544 63.3734 25.1223 63.3208 24.6387 63.12C24.1551 62.9192 23.7417 62.5791 23.4509 62.1428C23.1601 61.7065 23.0049 61.1935 23.0049 60.6688C23.0049 59.9651 23.2837 59.2903 23.7801 58.7927C24.2764 58.2952 24.9496 58.0156 25.6515 58.0156Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6745 33.9141C12.1989 33.9132 12.7118 34.0684 13.1483 34.3599C13.5848 34.6514 13.9252 35.0661 14.1265 35.5516C14.3278 36.0371 14.3809 36.5715 14.2791 37.0873C14.1773 37.603 13.9251 38.0769 13.5546 38.449C13.184 38.8211 12.7117 39.0746 12.1974 39.1774C11.683 39.2803 11.1498 39.2279 10.6652 39.0269C10.1806 38.8259 9.76635 38.4853 9.47488 38.0482C9.18342 37.6111 9.02783 37.0972 9.02783 36.5714C9.02783 35.8674 9.30653 35.1921 9.80275 34.6939C10.299 34.1956 10.9721 33.9152 11.6745 33.9141Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.5128 9.72461C26.0363 9.72461 26.548 9.88021 26.9832 10.1717C27.4185 10.4633 27.7577 10.8777 27.958 11.3625C28.1583 11.8473 28.2107 12.3807 28.1086 12.8954C28.0065 13.41 27.7544 13.8828 27.3843 14.2539C27.0142 14.6249 26.5426 14.8776 26.0292 14.98C25.5158 15.0823 24.9836 15.0298 24.5 14.829C24.0164 14.6282 23.6031 14.2881 23.3122 13.8518C23.0214 13.4155 22.8662 12.9025 22.8662 12.3778C22.8662 12.0294 22.9347 11.6844 23.0677 11.3625C23.2007 11.0406 23.3956 10.7481 23.6414 10.5017C23.8872 10.2553 24.1789 10.0599 24.5 9.92657C24.8211 9.79323 25.1653 9.72461 25.5128 9.72461Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M53.361 9.71094C53.8855 9.71011 54.3984 9.86525 54.8349 10.1567C55.2714 10.4482 55.6118 10.863 55.8131 11.3485C56.0143 11.834 56.0674 12.3684 55.9656 12.8842C55.8638 13.3999 55.6117 13.8738 55.2411 14.2459C54.8706 14.6179 54.3982 14.8714 53.8839 14.9743C53.3696 15.0772 52.8364 15.0248 52.3517 14.8238C51.8671 14.6228 51.4529 14.2822 51.1614 13.8451C50.8699 13.408 50.7144 12.8941 50.7144 12.3683C50.7155 11.6646 50.9945 10.99 51.4905 10.492C51.9865 9.99399 52.659 9.71317 53.361 9.71094Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M67.296 33.7626C67.8207 33.7618 68.3338 33.917 68.7704 34.2087C69.2069 34.5004 69.5473 34.9154 69.7485 35.4011C69.9497 35.8868 70.0025 36.4215 69.9003 36.9374C69.7982 37.4533 69.5456 37.9271 69.1747 38.299C68.8037 38.6709 68.331 38.9241 67.8164 39.0265C67.3018 39.1289 66.7684 39.0759 66.2839 38.8743C65.7993 38.6726 65.3853 38.3314 65.0944 37.8937C64.8034 37.4561 64.6486 36.9417 64.6494 36.4158C64.6505 35.7125 64.9297 35.0383 65.4258 34.5409C65.9219 34.0436 66.5945 33.7637 67.296 33.7626Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M53.4455 57.9492C53.9701 57.9484 54.4832 58.1036 54.9198 58.3953C55.3563 58.687 55.6968 59.102 55.8979 59.5877C56.0991 60.0735 56.1519 60.6081 56.0498 61.124C55.9476 61.6399 55.6951 62.1138 55.3241 62.4857C54.9531 62.8575 54.4804 63.1107 53.9658 63.2131C53.4512 63.3155 52.9178 63.2626 52.4333 63.0609C51.9487 62.8592 51.5348 62.518 51.2438 62.0803C50.9529 61.6427 50.798 61.1283 50.7988 60.6024C50.7988 59.8987 51.0777 59.2239 51.574 58.7263C52.0703 58.2288 52.7435 57.9492 53.4455 57.9492Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.4277 67.4033C58.781 67.4025 59.1266 67.5068 59.4208 67.7029C59.715 67.8991 59.9445 68.1783 60.0802 68.5053C60.216 68.8323 60.252 69.1923 60.1836 69.5398C60.1151 69.8872 59.9454 70.2065 59.6959 70.4573C59.4464 70.708 59.1282 70.8789 58.7818 70.9483C58.4353 71.0177 58.0761 70.9825 57.7496 70.8471C57.4231 70.7118 57.1441 70.4824 56.9477 70.188C56.7513 69.8935 56.6465 69.5473 56.6465 69.1932C56.6465 68.7192 56.834 68.2646 57.1679 67.929C57.5019 67.5935 57.9549 67.4044 58.4277 67.4033Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7109 67.4747C21.0642 67.4738 21.4098 67.5781 21.704 67.7743C21.9981 67.9704 22.2276 68.2497 22.3634 68.5766C22.4992 68.9036 22.5352 69.2636 22.4668 69.6111C22.3983 69.9586 22.2286 70.2779 21.9791 70.5286C21.7296 70.7794 21.4114 70.9502 21.065 71.0196C20.7185 71.089 20.3593 71.0538 20.0328 70.9185C19.7063 70.7832 19.4272 70.5538 19.2309 70.2593C19.0345 69.9649 18.9297 69.6187 18.9297 69.2645C18.9297 68.7906 19.1172 68.3359 19.4511 68.0004C19.7851 67.6649 20.2381 67.4758 20.7109 67.4747Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.7813 34.7991C2.13532 34.7982 2.48158 34.903 2.77609 35.0999C3.07061 35.2968 3.3001 35.577 3.43537 35.905C3.57065 36.2329 3.6056 36.5938 3.53581 36.9417C3.46602 37.2897 3.29464 37.6089 3.04343 37.859C2.79221 38.109 2.47252 38.2786 2.12497 38.3461C1.77741 38.4136 1.41767 38.376 1.09149 38.238C0.765302 38.1001 0.487388 37.8681 0.293052 37.5714C0.0987165 37.2748 -0.00325189 36.9269 7.90784e-05 36.5721C7.77696e-05 36.0981 0.187614 35.6435 0.521539 35.3079C0.855464 34.9724 1.30851 34.7833 1.7813 34.7822V34.7991Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.564 2.03809C20.9175 2.03726 21.2632 2.14163 21.5575 2.33798C21.8517 2.53433 22.0812 2.81382 22.2169 3.14104C22.3525 3.46826 22.3882 3.82848 22.3195 4.17607C22.2507 4.52366 22.0806 4.84296 21.8307 5.09352C21.5807 5.34409 21.2622 5.51464 20.9155 5.58357C20.5687 5.65249 20.2094 5.6167 19.883 5.48071C19.5566 5.34472 19.2777 5.11466 19.0819 4.81968C18.886 4.5247 18.7819 4.17807 18.7827 3.82371C18.7827 3.35014 18.9704 2.89596 19.3044 2.56109C19.6385 2.22622 20.0916 2.03809 20.564 2.03809Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.2976 1.94922C58.6508 1.94922 58.996 2.0542 59.2896 2.25087C59.5832 2.44754 59.812 2.72707 59.9472 3.05412C60.0823 3.38117 60.1177 3.74104 60.0488 4.08824C59.9799 4.43543 59.8098 4.75435 59.5601 5.00466C59.3105 5.25497 58.9923 5.42544 58.646 5.4945C58.2996 5.56357 57.9406 5.52812 57.6144 5.39265C57.2881 5.25718 57.0093 5.02777 56.8131 4.73344C56.6169 4.4391 56.5122 4.09305 56.5122 3.73906C56.5117 3.50386 56.5575 3.27086 56.647 3.05346C56.7365 2.83606 56.868 2.63853 57.0339 2.47221C57.1998 2.3059 57.3969 2.17409 57.6137 2.08433C57.8306 1.99458 58.063 1.94867 58.2976 1.94922Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77.2146 34.6348C77.5679 34.6348 77.9133 34.7399 78.207 34.9367C78.5007 35.1336 78.7295 35.4133 78.8645 35.7406C78.9996 36.0679 79.0347 36.428 78.9654 36.7753C78.8962 37.1226 78.7257 37.4416 78.4756 37.6917C78.2255 37.9418 77.907 38.112 77.5604 38.1806C77.2138 38.2491 76.8546 38.2131 76.5285 38.077C76.2023 37.9409 75.9237 37.7108 75.728 37.4159C75.5324 37.121 75.4284 36.7746 75.4292 36.4204C75.4292 36.1856 75.4754 35.953 75.5652 35.7361C75.655 35.5192 75.7866 35.3221 75.9524 35.1563C76.1183 34.9904 76.3151 34.859 76.5317 34.7695C76.7483 34.68 76.9804 34.6342 77.2146 34.6348Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M69.1149 17.0938C69.5473 17.0929 69.9702 17.2208 70.3301 17.4611C70.69 17.7014 70.9706 18.0434 71.1365 18.4437C71.3024 18.844 71.346 19.2847 71.2618 19.7099C71.1777 20.1351 70.9695 20.5257 70.6638 20.8322C70.358 21.1387 69.9684 21.3473 69.5442 21.4317C69.1201 21.5161 68.6805 21.4723 68.2812 21.3061C67.8818 21.1398 67.5407 20.8585 67.301 20.4977C67.0612 20.1369 66.9337 19.7129 66.9346 19.2795C66.9346 18.6998 67.1643 18.1438 67.5732 17.7339C67.9821 17.324 68.5366 17.0938 69.1149 17.0938Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.4518 4.05128e-06C39.884 -0.000829225 40.3068 0.126898 40.6666 0.367022C41.0264 0.607145 41.3071 0.94887 41.4731 1.34895C41.639 1.74903 41.6829 2.18948 41.5991 2.61456C41.5153 3.03964 41.3075 3.43024 41.0022 3.73693C40.6968 4.04361 40.3076 4.2526 39.8838 4.33744C39.4599 4.42228 39.0204 4.37916 38.621 4.21354C38.2216 4.04791 37.8802 3.76723 37.6399 3.40701C37.3997 3.04679 37.2715 2.62322 37.2715 2.18992C37.2715 1.60985 37.5011 1.05347 37.9098 0.642902C38.3186 0.232333 38.8732 0.00111958 39.4518 4.05128e-06Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.78824 17.1992C10.2205 17.1984 10.6432 17.3261 11.003 17.5662C11.3628 17.8064 11.6435 18.1481 11.8095 18.5482C11.9754 18.9483 12.0193 19.3887 11.9355 19.8138C11.8517 20.2389 11.644 20.6295 11.3386 20.9361C11.0333 21.2428 10.644 21.4518 10.2202 21.5367C9.79629 21.6215 9.35684 21.5784 8.95742 21.4128C8.55801 21.2471 8.2166 20.9664 7.97637 20.6062C7.73615 20.246 7.60792 19.8224 7.60791 19.3891C7.60736 19.1018 7.66336 18.8171 7.77268 18.5514C7.88201 18.2857 8.04252 18.0443 8.24504 17.8409C8.44756 17.6375 8.68808 17.4761 8.95288 17.366C9.21769 17.2559 9.50156 17.1992 9.78824 17.1992Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.90591 51.5176C10.3381 51.5167 10.7609 51.6445 11.1207 51.8846C11.4805 52.1247 11.7611 52.4664 11.9271 52.8665C12.0931 53.2666 12.137 53.7071 12.0532 54.1321C11.9693 54.5572 11.7616 54.9478 11.4563 55.2545C11.1509 55.5612 10.7617 55.7702 10.3378 55.855C9.91396 55.9399 9.47451 55.8967 9.07509 55.7311C8.67568 55.5655 8.33425 55.2848 8.09402 54.9246C7.8538 54.5644 7.72559 54.1408 7.72559 53.7075C7.72558 53.1274 7.95515 52.571 8.36392 52.1605C8.77269 51.7499 9.32727 51.5187 9.90591 51.5176Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.3466 68.625C39.7789 68.625 40.2014 68.7536 40.5607 68.9944C40.9201 69.2352 41.2001 69.5775 41.3653 69.9779C41.5305 70.3783 41.5735 70.8188 41.4889 71.2437C41.4043 71.6686 41.1958 72.0588 40.8898 72.3649C40.5839 72.671 40.1943 72.8792 39.7703 72.9633C39.3462 73.0473 38.9068 73.0033 38.5077 72.8369C38.1087 72.6705 37.7678 72.3892 37.5282 72.0285C37.2887 71.6678 37.1613 71.244 37.1621 70.8107C37.1621 70.5233 37.2187 70.2388 37.3285 69.9733C37.4383 69.7078 37.5993 69.4667 37.8022 69.2637C38.0051 69.0607 38.246 68.8998 38.511 68.7902C38.776 68.6806 39.06 68.6245 39.3466 68.625Z'
        fill='#FAFAFA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M69.1528 51.4083C69.585 51.4083 70.0076 51.5368 70.3669 51.7776C70.7262 52.0184 71.0062 52.3607 71.1714 52.7611C71.3366 53.1615 71.3797 53.602 71.295 54.027C71.2104 54.4519 71.0019 54.8421 70.696 55.1482C70.3901 55.4543 70.0004 55.6625 69.5764 55.7465C69.1524 55.8305 68.713 55.7866 68.3139 55.6202C67.9148 55.4538 67.5739 55.1725 67.3344 54.8118C67.0949 54.4511 66.9674 54.0273 66.9683 53.594C66.9683 53.3066 67.0248 53.022 67.1346 52.7566C67.2445 52.4911 67.4055 52.25 67.6084 52.047C67.8113 51.8439 68.0521 51.683 68.3171 51.5734C68.5821 51.4638 68.8661 51.4077 69.1528 51.4083Z'
        fill='#FAFAFA'
      />
    </g>
    <defs>
      <clipPath id='clip0_39_6692'>
        <rect
          width='79'
          height='73'
          fill='white'
        />
      </clipPath>
    </defs>
  </svg>
)
