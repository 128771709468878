export const cardsData = [
  {
    title: 'Researching',
    text: 'Discord public community where we continuously encourage networking among entrepreneurs, developers and early-adopters of digital technologies. This is a space where we encourage engagement and exchange of business value among the different members.',
    buttonText: 'Contact',
    targetId: 'researching-section',
    disabled: false
  },
  {
    title: 'Consultancy',
    text: 'A reward-based crowdfunding platform where multiple on-chain projects and start-ups can start their funding campaigns under disintermediated standards of security, privacy and transparency.',
    targetId: 'consultancy-section',
    buttonText: 'Contact',
    disabled: false,
    svg: 'innovatio-projects-card'
  },
  {
    title: `Development`,
    targetId: 'development-section',
    text: 'Educational platform linked to Medium about blockchain technology, you can find official articles from Innovatio Community, as well as hundreds of opinion articles from the Blockchain community.',
    buttonText: 'Contact',
    disabled: false
  }
]
