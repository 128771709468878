import { useEffect, useState } from 'react'

export const useLockedBody = () => {
  const [isLocked, setIsLocked] = useState<boolean>(false)

  useEffect(() => {
    if (isLocked) {
      document.body.style.overflowY = 'hidden'
      document.body.style.position = 'fixed'
    } else {
      document.body.style.overflowY = 'auto'
      document.body.style.position = 'static'
    }
  }, [isLocked])

  const toggleLockBody = () => setIsLocked(!isLocked)
  return {
    isLocked,
    toggleLockBody
  }
}
