import { SVGProps } from 'react'

export const HeaderLogoMobile = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.4446 0.668657C14.5566 0.435111 14.625 0.150376 14.852 0.00640869C18.6241 0.188767 22.3713 1.12615 25.7764 2.82496C26.3641 3.0937 27.0669 3.37524 27.2784 4.07268C27.4494 4.69974 27.3188 5.37158 27.2535 6.00824C26.9456 9.49864 26.0127 12.9379 24.4859 16.0699C21.7617 14.3551 19.3673 12.1028 17.374 9.53063C17.0879 9.16592 16.8453 8.76281 16.5001 8.45568C15.7382 7.80943 14.4539 8.00138 13.9222 8.86838C13.2816 9.92094 12.6565 10.9991 11.8324 11.9141C11.1421 12.6915 10.5108 13.5937 9.53126 14.0064C8.1661 14.5823 6.47752 14.144 5.60059 12.8963C9.46285 9.64901 12.585 5.44197 14.4446 0.668657Z'
      fill='#F5A800'
    />
    <path
      d='M0.870683 3.18007C4.64276 1.17733 8.86575 0.0991773 13.1012 0C11.4561 4.33821 8.67606 8.19973 5.24605 11.2166C4.82002 11.6261 4.15454 11.9301 4.09234 12.5987C3.96173 13.3378 4.50904 13.9232 4.95995 14.4095C6.25359 15.7308 8.29667 16.1083 9.97902 15.4589C11.0612 15.0558 11.8728 14.1888 12.6005 13.309C13.6547 12.1956 14.4508 10.8615 15.2469 9.55302C15.7071 9.633 15.931 10.0969 16.2078 10.4296C17.9337 12.7555 20.0389 14.771 22.3495 16.4602C22.847 16.8058 23.5809 17.1769 23.4503 17.9223C23.2264 18.6485 22.6822 19.6243 21.8021 19.3876C20.1944 18.3798 18.764 17.0969 17.3957 15.7724C16.8484 15.2573 16.4223 14.6111 15.8315 14.1472C15.3775 13.7825 14.6747 14.3071 14.7649 14.8606C14.7493 15.1357 14.9639 15.3277 15.1132 15.5261C16.7115 17.356 18.5401 18.9589 20.5023 20.3601C21.0185 20.6673 21.2113 21.4383 20.7977 21.915C20.4898 22.3085 20.154 22.8652 19.588 22.7948C19.0407 22.8396 18.6582 22.3661 18.2415 22.0878C16.5747 20.8848 14.9732 19.5667 13.5894 18.0247C13.3562 17.7783 13.1229 17.4904 12.7871 17.4008C12.417 17.4008 12.0159 17.6888 11.9786 18.0919C11.9039 18.5334 12.302 18.8149 12.5414 19.1156C14.0372 20.7952 15.7382 22.2637 17.5356 23.585C17.9679 23.8569 17.9927 24.4264 17.8684 24.8807C17.517 25.4278 16.8359 25.9205 16.1953 25.5654C14.2331 24.2952 12.4419 22.7436 10.8466 21.0096C10.601 20.7441 10.3771 20.4497 10.0816 20.2386C9.65561 19.9538 9.00879 20.3122 8.99325 20.84C8.92483 21.2495 9.28556 21.5183 9.50013 21.8062C10.6943 23.1179 11.9972 24.3272 13.3904 25.4118C13.8009 25.7509 14.2735 26.0229 14.6094 26.4452C14.7182 26.8579 14.7524 27.3985 14.401 27.7025C14.0745 28.064 13.4899 28.0864 13.095 27.8368C8.85953 25.0791 5.36732 21.1344 3.06925 16.5402C1.26873 12.8995 0.214534 8.87157 -3.64352e-05 4.78931C-0.0342433 4.14305 0.298496 3.47121 0.867573 3.18007H0.870683Z'
      fill='#222222'
    />
  </svg>
)
