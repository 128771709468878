import { FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './FooterNavigationContainer.module.css'

export const FooterNavigationContainer: FC = () => {
  return (
    <div className={styles.footerMapContainer}>
      <h4 className={styles.footerTitle}>Map</h4>
      <div className={styles.footerMapNavigation}>
        <div>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to='/about-us'>About Us</Link>
          </li>
          <li>
            <Link to='/b2b-services'>B2B Services</Link>
          </li>
        </div>
        <div>
          <li>
            <Link to='/community'>Our Community</Link>
          </li>
          <li>
            <Link to='/clients'>Partnerships</Link>
          </li>
        </div>
      </div>
    </div>
  )
}
