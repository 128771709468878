import {
  EffectFade,
  Navigation,
  Pagination,
  SwiperOptions
} from 'swiper'
// Import Swiper styles
import 'swiper/css/bundle'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { HomePartnershipsSlideCard } from 'view/components'

import styles from './HomePartnershipsSlider.module.css'
import {
  IPartnershipCardData,
  partnershipsSliderData
} from './constants'

const swiperSharedOptions: SwiperOptions = {
  pagination: {
    type: 'progressbar',
    clickable: true,
    enabled: true,
    horizontalClass: styles.swiperPagination
  }
}

export const HomePartnershipsSlider = () => {
  return (
    <div className={styles.wrapper}>
      <Swiper
        allowSlideNext={true}
        allowSlidePrev={true}
        allowTouchMove={true}
        centeredSlides={true}
        slidesPerView={3}
        initialSlide={1}
        slidesPerGroup={1}
        spaceBetween={10}
        pagination={{
          type: 'progressbar',
          clickable: true,
          enabled: true,
          horizontalClass: styles.swiperPagination
        }}
        modules={[Pagination, Navigation, EffectFade]}
        className={styles.container}
        breakpoints={{
          2700: {
            slidesPerView: 3,
            width: 1920,
            initialSlide: 0,
            ...swiperSharedOptions
          },
          1250: {
            width: 1950,
            slidesPerView: 3,
            initialSlide: 0,
            ...swiperSharedOptions
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
            width: 1250,
            initialSlide: 0,
            ...swiperSharedOptions
          },
          550: {
            width: 450,
            slidesPerView: 1,
            spaceBetween: 0,
            initialSlide: 0,
            ...swiperSharedOptions
          },
          300: {
            width: 320,
            slidesPerView: 1,
            spaceBetween: 0,
            initialSlide: 0,
            ...swiperSharedOptions
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 0,
            width: 650,
            initialSlide: 1,
            ...swiperSharedOptions
          }
        }}
      >
        {partnershipsSliderData.map(
          (item: IPartnershipCardData, index) => (
            <SwiperSlide
              className={styles.slideContent}
              key={index}
            >
              <HomePartnershipsSlideCard
                website={item.website}
                key={index}
                description={item.description}
                imageUrl={item.imageUrl}
                name={item.name}
              />
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  )
}
