import { EXTERNAL_URL_DATA } from 'lib/utils/constants/externalUrls'
import { FC } from 'react'
import { SVG } from 'view/elements'

import styles from './SocialFooterContainer.module.css'

export const FooterSocialContainer: FC = () => {
  return (
    <div className={styles.socialContainer}>
      <a
        href={EXTERNAL_URL_DATA.facebook}
        target='_blank'
        rel='noreferrer'
      >
        <SVG variant='facebook-icon' />
      </a>
      <a
        href={EXTERNAL_URL_DATA.instagram}
        target='_blank'
        rel='noreferrer'
      >
        <SVG variant='instagram-icon' />
      </a>
      <a
        href={EXTERNAL_URL_DATA.twitter}
        target='_blank'
        rel='noreferrer'
      >
        <SVG variant='twitter-icon' />
      </a>
      <a
        href={EXTERNAL_URL_DATA.discord}
        target='_blank'
        rel='noreferrer'
      >
        <SVG variant='discord-icon' />
      </a>
      <a
        href={EXTERNAL_URL_DATA.lintktree}
        target='_blank'
        rel='noreferrer'
      >
        <SVG variant='lintr-footer-icon' />
      </a>
    </div>
  )
}
