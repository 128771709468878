import { b2bBussinessServicesFigure } from 'lib/utils/constants/imageUrls'

import styles from './B2bMainSection.module.css'

const businessText = `Innovatio is a Web3 start-up that
facilitates the adoption of this ecosystem and its
services to companies and organizations in the traditional
world. Thanks to its large team, knowledge and more than
30 years of accumulated experience, we are a trusted brand
to help you achieve this goal. Here are some of our services, f
eel free to contact us and tell us more about what you are looking for,
we have a large network of contacts to meet your demands,
¿what are you waiting for to be part of the Web3?`

export const B2bMainSection = () => {
  return (
    <section className={styles.sectionContainer}>
      <div className={styles.leftSlot}>
        <div className={styles.leftSlotContent}>
          <div className={styles.leftSlotHeader}>
            <h4>Innovatio Business Services</h4>
          </div>
          <div className={styles.leftSlotBody}>
            <p>{businessText}</p>
          </div>
        </div>
      </div>
      <div className={styles.rightSlot}>
        <img
          src={b2bBussinessServicesFigure}
          alt='Section image'
          className={styles.rightSlotImage}
        />
      </div>
    </section>
  )
}
