import { SVGProps } from 'react'

export const LinkedInIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1797_152)'>
      <path
        d='M21.3465 21.0229H16.7855V20.7179C16.7855 18.382 16.7946 16.046 16.7779 13.7101C16.7743 13.2113 16.7241 12.7028 16.6134 12.2174C16.4286 11.407 15.9858 10.7708 15.1344 10.5678C14.2088 10.3474 13.3756 10.5396 12.6998 11.2578C12.1555 11.8365 11.9722 12.5368 11.9752 13.3195C11.9854 15.7831 11.9788 18.2471 11.9788 20.7112C11.9788 20.8107 11.9788 20.9101 11.9788 21.0229H7.43408V7.19543H11.9742V9.09411C12.0555 9.0003 12.1007 8.95776 12.1347 8.90752C13.0851 7.49376 14.4266 6.84378 16.097 6.84891C17.3679 6.85301 18.5357 7.17646 19.5105 8.04225C20.4092 8.84037 20.9088 9.87327 21.1317 11.0364C21.2546 11.6771 21.3307 12.3363 21.3363 12.9884C21.3592 15.597 21.346 18.2061 21.3465 20.8148C21.3465 20.8742 21.3465 20.9332 21.3465 21.0234V21.0229Z'
        fill='#263238'
      />
      <path
        d='M4.87663 7.19531V21.0197H0.347656V7.19531H4.87663Z'
        fill='#263238'
      />
      <path
        d='M5.18874 2.9224C5.18011 4.10651 4.35047 5.05226 3.1639 5.23526C2.514 5.33522 1.88391 5.28499 1.29341 4.97486C0.438393 4.5248 -0.046492 3.57494 0.0758715 2.61073C0.206359 1.58348 0.885197 0.819699 1.88035 0.583902C2.4155 0.457289 2.95116 0.454726 3.48073 0.606969C4.54036 0.911455 5.19686 1.80646 5.18874 2.9224Z'
        fill='#263238'
      />
    </g>
    <defs>
      <clipPath id='clip0_1797_152'>
        <rect
          width='21.2928'
          height='20.5323'
          fill='white'
          transform='translate(0.0571289 0.490479)'
        />
      </clipPath>
    </defs>
  </svg>
)
