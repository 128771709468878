import { SVGProps } from 'react'

export const DiscordBePartCardIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={32}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M27.81 2.899C25.717 1.042 23.063.016 20.315 0v.8s2.856-.173 6.393 3.457A19.319 19.319 0 0 0 16.002 1C12.208 1 8.494 2.13 5.295 4.257 8.831.627 11.688.8 11.688.8V0C8.941.016 6.286 1.042 4.193 2.899.223 8.23 0 19.892 0 19.892a10.185 10.185 0 0 0 3.737 3.122 9.741 9.741 0 0 0 4.696.976l1.717-2.605a9.923 9.923 0 0 1-4.769-3.192A19.348 19.348 0 0 0 16 21.386c3.757 0 7.438-1.107 10.619-3.193a9.925 9.925 0 0 1-4.77 3.192l1.718 2.605a9.74 9.74 0 0 0 4.697-.975A10.184 10.184 0 0 0 32 19.892S31.78 8.23 27.81 2.899Zm-16.8 13.695a2.797 2.797 0 0 1-1.652-.417 2.974 2.974 0 0 1-1.141-1.313 3.13 3.13 0 0 1-.237-1.754c.091-.597.352-1.15.75-1.59.396-.44.91-.746 1.476-.877a2.78 2.78 0 0 1 1.693.14c.539.223 1 .609 1.326 1.108.326.5.5 1.09.5 1.694a3.003 3.003 0 0 1-.763 2.088 2.76 2.76 0 0 1-1.953.92Zm9.968 0a2.796 2.796 0 0 1-1.65-.417 2.974 2.974 0 0 1-1.142-1.313 3.131 3.131 0 0 1-.237-1.754c.091-.597.352-1.15.75-1.59.396-.44.91-.746 1.476-.877a2.78 2.78 0 0 1 1.694.14c.538.223 1 .609 1.325 1.108.326.5.5 1.09.5 1.694a3.003 3.003 0 0 1-.763 2.088 2.76 2.76 0 0 1-1.953.92Z'
      fill='#092136'
    />
  </svg>
)
