import { useNewWindow } from 'lib/hooks'
import { IAboutSliderData } from 'lib/interfaces'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { SVG } from 'view/elements'

import styles from './AboutMembersSlide.module.css'

export const AboutMembersSlide: FC<IAboutSliderData> = ({
  bio,
  discordUrl,
  img,
  role,
  linkedinUrl,
  name,
  twitterUrl
}) => {
  const [toggleText, setToggleText] =
    useState<boolean>(false)

  return (
    <article className={styles.cardContainer}>
      {!toggleText ? (
        <>
          <div className={styles.cardHeader}>
            <h3 className={styles.imageText}>{name}</h3>
            <img
              src={img}
              alt='member image'
            />
          </div>
          <div className={styles.cardBody}>
            <h5>{role}</h5>
            <p>
              {bio.split(' ').slice(0, 24).join(' ')} ...
              <label
                className={styles.toggleText}
                onClick={() => setToggleText(!toggleText)}
              >
                Learn More
              </label>
            </p>
          </div>
          <div className={styles.cardFooter}>
            <div className={styles.leftSlot}>Contact</div>
            <div className={styles.rightSlot}>
              <a
                onClick={() => {
                  navigator.clipboard.writeText(discordUrl)
                  toast('Discord user copied to clipboard')
                }}
              >
                <SVG variant='discord-icon' />
              </a>
              <a onClick={() => useNewWindow(twitterUrl)}>
                <SVG variant='twitter-icon' />
              </a>
              <a onClick={() => useNewWindow(linkedinUrl)}>
                <SVG variant='linkedin-icon' />
              </a>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.cardBodyFullBio}>
          <label
            className={styles.toggleText}
            onClick={() => setToggleText(!toggleText)}
          >
            See Less
          </label>
          <h3>{name}</h3>
          <h5>{role}</h5>
          <p className={styles.cardBodyFullBioText}>
            {bio}
          </p>
        </div>
      )}
    </article>
  )
}
