import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'swiper/css/bundle'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/assets/font/Gilroy/Gilroy-Bold/Gilroy-Bold.ttf'
import './styles/assets/font/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.otf'
import './styles/assets/font/Gilroy/Gilroy-Light/Gilroy-Light.otf'
import './styles/assets/font/Gilroy/Gilroy-Medium/Gilroy-Medium.ttf'
import './styles/index.css'

ReactGA.initialize([
  {
    trackingId: 'G-NWSD7DT2RC'
  },
  {
    trackingId: 'GTM-KG4LKDXS'
  }
])

ReactGA.gtag('GTM-KG4LKDXS')
ReactGA.ga('G-NWSD7DT2RC')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      position='bottom-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='dark'
    />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
