import { FC, ReactNode } from 'react'
import { Footer, Header } from 'view/prototypes'

import styles from './MainLayout.module.css'

interface Props {
  children: ReactNode | ReactNode[]
}

export const MainLayout: FC<Props> = ({ children }) => {
  return (
    <main className={styles.container}>
      <Header />
      {children}
      <Footer />
    </main>
  )
}
