export const weAreInnovatioText = `Innovatio is a European
start-up established in the web ecosystem3 thanks to
its two years of experience, developing mentoring, c
onsulting and community two years of experience,
developing mentoring, consulting and community to
entrepreneurs, developers and entrepreneurs,
developers and projects within the ecosystems of
Cardano, Milkomeda, Polkadot Cardano, Milkomeda,
Polkadot, Avalanche and Ethereum, among others.
Currently, its main business model is to offer
research, consulting and B2B development in order
to facilitate the adoption of this to facilitate
the adoption of this technology to companies in
the traditional world, along with a team that
with a team that has more than 30 years of
experience in the technology and IT sector.`

export const whatWeDoText = `As a brand we work on the
development of our B2B advisory, consulting
and development services to other companies
that are looking to define a business plan
and development model for applications,
communities, community and marketing strategies.
business plan and a development model for
applications, communities and community
and marketing strategies. All this added to
the great Innovatio's Network that contains c
ontacts, projects, communities and organizations
of great quality to collaborate, converge
synergies and help in our purpose.`

export const howWeBornText1 = `Innovatio was born
from the need to create a convergence
space where entrepreneurs, developers
and enthusiasts of the digital world
can connect, network, collaborate and
exchange business value among the members
and projects of the community. can
connect, network, collaborate and
exchange business value among the
members and projects of the community,
giving them the possibility of creating
a network of highly create a network
of contacts highly related to the web3
without physical or time restrictions. `

export const howWeBornText2 = `The first community
that believed in our community philosophy
was Cardano, in the first quarter of 2021
we received our first $25k grant to develop
this space and foster the creation of a
new community. dollars to develop this
space and foster networking and collaborations
between the different projects, members
and entrepreneurs of Cardano's ecosystem.
Cardano's ecosystem, throughout this
year and 2022, we will once again
validate our brand and what it stands
for, raising over $200k.`
