import { useNewWindow } from 'lib/hooks'
import { EXTERNAL_URL_DATA } from 'lib/utils/constants/externalUrls'
import { aboutB2bCardFigure } from 'lib/utils/constants/imageUrls'
import { Button, SVG, Title } from 'view/elements'

import styles from './AboutB2bSection.module.css'

const sectionTitle = `Use our spaces, be part of the Innovatio's Network`

const cardText = `If your company is looking to adopt web3 services such as payment gateways for cryptocurrencies, development of custom websites and applications from scratch, SCRUM Master, blockchain consulting and research, metaverse, traceability, data-storage, and more, we can help you.`

export const AboutB2bSection = () => {
  return (
    <>
      <div className={styles.titleContainer}>
        <Title
          dotted={true}
          size={'large'}
          text={sectionTitle}
        />
      </div>

      <section className={styles.sectionContainer}>
        <div className={styles.sectionContent}>
          <div className={styles.cardContainer}>
            <article className={styles.b2bCard}>
              <div className={styles.cardFigureSlot}>
                <img
                  src={aboutB2bCardFigure}
                  alt=''
                />
              </div>
              <div className={styles.cardContentSlot}>
                <h3>B2B Services</h3>
                <p>{cardText}</p>
                <div className={styles.cardButtonContainer}>
                  <Button variant='outlined'>
                    View Services
                  </Button>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className={styles.socialWrapper}>
          <article className={styles.socialContainer}>
            <p>Take a view to all our social networks.</p>
            <div className={styles.socialButtonContainer}>
              <Button
                type='button'
                variant='default'
                onClick={() =>
                  useNewWindow(EXTERNAL_URL_DATA.discord)
                }
              >
                <div className={styles.buttonContent}>
                  <SVG variant='discord-bepart-card' />
                  <span>Discord</span>
                </div>
              </Button>

              <Button
                type='button'
                variant='default'
                onClick={() =>
                  useNewWindow(EXTERNAL_URL_DATA.lintktree)
                }
              >
                <div className={styles.buttonContent}>
                  <SVG variant='lintr-icon' />
                  <span>Lintr.ee</span>
                </div>
              </Button>
            </div>
          </article>
        </div>
      </section>
    </>
  )
}
