import { FC, ReactNode } from 'react'

import styles from './PageContentWrapper.module.css'

interface Props {
  children: ReactNode | ReactNode[]
}
export const PageContentWrapper: FC<Props> = ({
  children
}) => {
  return (
    <div className={styles.contentWrapper}>{children}</div>
  )
}
