/* eslint-disable no-irregular-whitespace */
import { useNewWindow } from 'lib/hooks'
import consultacyPdf from 'lib/resources/consultacy.pdf'
import developmentPdf from 'lib/resources/development.pdf'
import researchingPdf from 'lib/resources/researching.pdf'
import { EXTERNAL_URL_DATA } from 'lib/utils/constants/externalUrls'
import {
  b2bConsultancyFigure,
  b2bDevelopmentFigure,
  b2bResearchingFigure
} from 'lib/utils/constants/imageUrls'
import { downloadPdf } from 'lib/utils/helpers'
import { FC, useEffect } from 'react'
import { B2bServicesSlider } from 'view/components'
import { Button, PageContentWrapper } from 'view/elements'
import { MainLayout } from 'view/layouts'
import {
  B2bLeftFigureSection,
  B2bMainSection,
  B2bRightFigureSection
} from 'view/prototypes'

import styles from './B2bServicePage.module.css'
import {
  consultancyText,
  developmentText,
  researchingText
} from './constants'

export const B2bServicePage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MainLayout>
      <div className={styles.b2bServicesPageBackground}>
        <div style={{ padding: '50px 0px' }}>
          <PageContentWrapper>
            <div className={styles.sectionsGap}>
              <B2bMainSection />
              <section style={{ width: '100%' }}>
                <B2bServicesSlider />
              </section>
              <B2bLeftFigureSection
                image={b2bResearchingFigure}
                text={researchingText}
                title={'Researching'}
                targetId={'researching-section'}
              >
                <div
                  className={
                    styles.RightFigureButtonContainer
                  }
                >
                  <Button
                    variant='primary'
                    disabled
                    onClick={() =>
                      useNewWindow(
                        EXTERNAL_URL_DATA.contactForm
                      )
                    }
                  >
                    <span>Contact</span>
                  </Button>
                  <Button
                    disabled
                    variant='outlined'
                    onClick={() =>
                      downloadPdf(researchingPdf)
                    }
                  >
                    <span>
                      Detailed Researching Services
                    </span>
                  </Button>
                </div>
              </B2bLeftFigureSection>
              <B2bRightFigureSection
                text={consultancyText}
                title={'Consultancy'}
                image={b2bConsultancyFigure}
                targetId={'consultancy-section'}
              >
                <div
                  className={
                    styles.RightFigureButtonContainer
                  }
                >
                  <Button
                    disabled
                    variant='primary'
                    onClick={() =>
                      useNewWindow(
                        EXTERNAL_URL_DATA.contactForm
                      )
                    }
                  >
                    <span>Contact</span>
                  </Button>
                  <Button
                    disabled
                    variant='outlined'
                    onClick={() =>
                      downloadPdf(consultacyPdf)
                    }
                  >
                    <span>
                      Detailed Consultancy Services
                    </span>
                  </Button>
                </div>
              </B2bRightFigureSection>
              <B2bLeftFigureSection
                image={b2bDevelopmentFigure}
                text={developmentText}
                title={'Development'}
                targetId={'development-section'}
              >
                <div
                  className={
                    styles.RightFigureButtonContainer
                  }
                >
                  <Button
                    disabled
                    variant='primary'
                    // onClick={() =>
                    //   useNewWindow(
                    //     EXTERNAL_URL_DATA.contactForm
                    //   )
                    // }
                  >
                    <span>Contact</span>
                  </Button>
                  <Button
                    disabled
                    variant='outlined'
                    onClick={() =>
                      downloadPdf(developmentPdf)
                    }
                  >
                    <span>
                      Detailed Development Services
                    </span>
                  </Button>
                </div>
              </B2bLeftFigureSection>
            </div>
          </PageContentWrapper>
        </div>
      </div>
    </MainLayout>
  )
}
