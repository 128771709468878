import { SVGProps } from 'react'

export const TwitterIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width='19'
    height='15'
    viewBox='0 0 19 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.5832 0.353935C17.5117 0.820172 17.2927 1.2509 16.9581 1.58337C16.7599 1.82345 16.54 2.04474 16.3012 2.24438C16.9273 2.20594 17.5416 2.05638 18.1151 1.80204C17.9766 2.09579 17.7878 2.36316 17.5575 2.59214C17.2785 2.93081 16.9584 3.2333 16.6046 3.49283C16.51 3.55363 16.434 3.63902 16.3846 3.74002C16.3353 3.84102 16.3145 3.95383 16.3247 4.06577C16.3612 5.98634 15.8767 7.88084 14.9224 9.54795C13.9681 11.2151 12.5799 12.5924 10.9053 13.5335C9.23076 14.4746 7.33236 14.9441 5.41213 14.8925C3.49191 14.8409 1.62162 14.27 0 13.2403C1.92391 13.3925 3.83644 12.831 5.37285 11.6631C4.60295 11.6211 3.86333 11.3496 3.24911 10.8835C2.63489 10.4174 2.17428 9.77826 1.9266 9.04807C2.45042 9.10484 2.97981 9.08147 3.49655 8.97852C2.66738 8.76118 1.93334 8.27552 1.40895 7.59747C0.884559 6.91941 0.599298 6.08683 0.597456 5.22966C1.11504 5.49336 1.67949 5.65253 2.2586 5.69802C1.48483 5.14094 0.940788 4.32078 0.728556 3.39125C0.523372 2.46948 0.678556 1.50432 1.16214 0.693194C1.78378 1.44436 2.50061 2.11134 3.29464 2.67721C4.81475 3.74676 6.58539 4.40517 8.43494 4.58917C8.74238 4.62094 8.86226 4.61402 8.82536 4.21538C8.7575 3.72134 8.79039 3.21827 8.92194 2.73725C9.05349 2.25624 9.28099 1.80699 9.59082 1.41625C9.90066 1.0255 10.2864 0.701743 10.7248 0.464019C11.1632 0.226295 11.6451 0.0797183 12.1416 0.0331898C12.6694 -0.0395939 13.2068 0.00764697 13.7136 0.171796C14.2204 0.335944 14.6835 0.612554 15.0684 0.980914C15.1443 1.05561 15.2398 1.10751 15.3438 1.13053C15.4479 1.15354 15.5562 1.1467 15.6566 1.11101C16.3231 0.922595 16.9695 0.669167 17.5863 0.353935H17.5832Z'
      fill='#FAFAFA'
    />
  </svg>
)
