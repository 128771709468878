import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { HomePartnershipsSlider } from 'view/components'
import { Button } from 'view/elements'

import styles from './HomePartnershipsSection.module.css'

export const HomePartnershipsSection: FC = () => {
  const navigate = useNavigate()
  return (
    <section className={styles.container}>
      <div className={styles.sectionPaddingWrapper}>
        <div className={styles.headerContainer}>
          <h4 className={styles.title}>Partnertships</h4>
          <div className={styles.line}></div>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.text}>
            Brand & Communities that trust on our experience
            and services.
          </p>
        </div>
      </div>
      <div className={styles.sliderContainer}>
        <HomePartnershipsSlider />
        <div className={styles.sectionPaddingWrapper}>
          <div className={styles.dividerContainer}>
            <hr className={styles.line} />
            <div className={styles.dividerButtonContainer}>
              <Button
                variant='default'
                onClick={() => navigate('/clients')}
              >
                Learn more
              </Button>
            </div>
            <hr className={styles.line} />
          </div>
        </div>
      </div>
    </section>
  )
}
