import { useWindowSize } from 'lib/hooks'
import { aboutHeadImage } from 'lib/utils/constants/imageUrls'
import { FC, useEffect } from 'react'
import { AboutMemberSlider } from 'view/components'
import {
  PageContentWrapper,
  SVG,
  Title
} from 'view/elements'
import { MainLayout } from 'view/layouts'
import {
  AboutB2bSection,
  AboutBrandbookSection
} from 'view/prototypes'

import styles from './AboutUsPage.module.css'
import {
  howWeBornText1,
  howWeBornText2,
  weAreInnovatioText,
  whatWeDoText
} from './constants'

export const AboutUsPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // Hace scroll hasta la parte superior de la página
  }, [])
  const { windowSize } = useWindowSize()
  return (
    <MainLayout>
      <div className={styles.welcomeContainer}>
        <div className={styles.imageContainer}>
          <img
            src={aboutHeadImage}
            className={styles.homeWelcomeImage}
            alt='Welcome to Innovatio Space'
          />
          <div className={styles.welcomeContent}>
            <Title
              dotted={true}
              size={'large'}
              text={'About Us'}
            />
          </div>
        </div>
      </div>
      <div className={styles.aboutPageBackground}>
        <PageContentWrapper>
          <section className={styles.aboutMainSection}>
            <article className={styles.leftSlot}>
              <div className={styles.slotHeader}>
                <h1 className={styles.articleTitle}>
                  We are Innovatio.
                </h1>
              </div>
              <div className={styles.slotBody}>
                <p>{weAreInnovatioText}</p>
              </div>
            </article>
            <article className={styles.rightSlot}>
              <div className={styles.slotHeader}>
                <h1 className={styles.articleTitle}>
                  And this is what we do
                </h1>
              </div>
              <div className={styles.slotBody}>
                <p>{whatWeDoText}</p>
              </div>
            </article>
            {windowSize.width &&
              windowSize.width > 1249 && (
                <div className={styles.line}></div>
              )}
            <article className={styles.leftSlot}>
              <div className={styles.slotHeader}>
                <h1 className={styles.articleTitle}>
                  Why and how we were born
                </h1>
              </div>
              <div className={styles.slotBody}>
                <p>{howWeBornText1}</p>
              </div>
            </article>
            <article className={styles.rightSlot}>
              {windowSize.width &&
              windowSize.width > 1250 ? (
                <div className={styles.slotHeader}>
                  <SVG variant='cardano-logo' />
                </div>
              ) : (
                <></>
              )}
              <div className={styles.slotBody}>
                <p>{howWeBornText2}</p>
              </div>
            </article>
            {windowSize.width &&
              windowSize.width > 1249 && (
                <div className={styles.line}></div>
              )}
          </section>
        </PageContentWrapper>
        <PageContentWrapper>
          <section
            className={styles.membersSectionContainer}
          >
            <div
              className={
                styles.membersSectionTitleContainer
              }
            >
              <Title
                dotted={true}
                size={'medium'}
                text={'We introduce you to our team'}
              />
            </div>
            <div
              className={styles.membersSectionTextContainer}
            >
              <p className={styles.membersSectionText}>
                {`Innovatio's team works remotely and
                flexibly, providing a framework for
                individual, collective and efficient digital
                work, meet our team!`}
              </p>
            </div>
          </section>
        </PageContentWrapper>
        <AboutMemberSlider />
        <PageContentWrapper>
          <div className={styles.sectionGap}>
            <AboutB2bSection />
            <div className={styles.line}></div>
            <AboutBrandbookSection />
          </div>
        </PageContentWrapper>
      </div>
    </MainLayout>
  )
}
