import { SVGProps } from 'react'

export const HamburgerMenuIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 100 80'
    width='30'
    height='30'
    {...props}
  >
    <rect
      width='100'
      height='10'
    ></rect>
    <rect
      y='30'
      width='100'
      height='10'
    ></rect>
    <rect
      y='60'
      width='100'
      height='10'
    ></rect>
  </svg>
)
