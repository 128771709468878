import { FC } from 'react'
import { Button, Title } from 'view/elements'

import styles from './B2bServicesSlide.module.css'

interface Props {
  title: string
  text: string
  buttonText: string
  isDisabled?: boolean
  targetId: string
}

export const B2bServicesSlide: FC<Props> = ({
  title,
  text,
  targetId,
  buttonText,
  isDisabled
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title
          dotted={true}
          size='small'
          text={title}
        />
      </div>

      <p className={styles.text}>{text}</p>
      <div className={styles.buttonContainer}>
        <Button
          disabled
          variant='default'
        >
          {buttonText}
        </Button>
        <Button
          disabled={isDisabled}
          variant='outlined'
          onClick={() =>
            document
              .getElementById(targetId)
              ?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          More info
        </Button>
      </div>
    </div>
  )
}
