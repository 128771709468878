import { ourClientsHeadFigure } from 'lib/utils/constants/imageUrls'
import { FC, useEffect } from 'react'
import { PageContentWrapper } from 'view/elements'
import { MainLayout } from 'view/layouts'
import { ClientsPartnershipsCardSection } from 'view/prototypes'

import styles from './OurClientsPage.module.css'

export const OurClientsPage: FC = () => {
  useEffect(() => {
    window.scrollTo(1, 0) // Hace scroll hasta la parte superior de la página
  }, [])
  return (
    <div className={styles.backgroundImage}>
      <MainLayout>
        <PageContentWrapper>
          <div className={styles.sectionsGap}>
            <div className={styles.welcomeContainer}>
              <div className={styles.imageContainer}>
                <img
                  src={ourClientsHeadFigure}
                  className={styles.homeWelcomeImage}
                  alt='Welcome to Innovatio Space'
                />
              </div>
            </div>
            <div className={styles.sectionTextContainer}>
              <h2>
                These are some of the clients who have
                trusted Innovatio to take their step into
                the new Web3 World<strong>.</strong>
              </h2>
            </div>
          </div>
        </PageContentWrapper>
        <ClientsPartnershipsCardSection />
      </MainLayout>
    </div>
  )
}
