import { IAboutSliderData } from 'lib/interfaces'
import {
  membersSlide1,
  membersSlide2,
  membersSlide3,
  membersSlide5,
  membersSlide6
} from 'lib/utils/constants/imageUrls'

export const aboutSliderData: IAboutSliderData[] = [
  {
    name: 'Ayax Labombarda',
    role: 'CEO & Founder',
    img: membersSlide1,
    linkedinUrl:
      'https://www.linkedin.com/in/ayax-labombarda-ugarte-73775617a/',
    twitterUrl: 'https://twitter.com/Ayaxlbok',
    discordUrl: 'AyaxLB#3333',
    bio: `He is the Founder & CEO at Innovatio, he has been working as SCRUM Master, CEO, Project Manager & Product Manager. Also he has helped Web3 projects in the development of their business models and their communities at Discord.
    He has managed to raise over $200k through the application of Web3 ecosystem grants. He is a super outgoing, transparent and sincere person, easy to deal with and puts all his energy into the vision of the project.
    `
  },
  {
    name: 'Daniel Rodriguez',
    role: 'CTO, Project Manager, SCRUM Master & IT Consultant',
    img: membersSlide2,
    linkedinUrl:
      'https://www.linkedin.com/in/danielfeliperodriguez/',
    twitterUrl: 'https://twitter.com/breakpoint0341',
    discordUrl: 'Daniel_Breapoint#5753',
    bio: `Daniel is a seasoned IT professional with 25+ yrs experience in software analysis, development, recruiting, & biz mgmt, focused on Blockchain, T-SQL, DBA tasks, database tuning & web dev with Open Source. He is a Blockchain expert, Technical Leader, community advisor, and helps with web3 adoption. He has led dev teams using Agile & been successful in converting customer reqs into solutions. He is a teacher, blockchain enthusiast (Cardano), financial crypto advisor, & active member of the LATAM Cardano Community.`
  },
  {
    name: 'Juan Pablo Barea',
    role: 'Community Leader & English Brand Ambassador',
    img: membersSlide3,
    linkedinUrl:
      'https://www.linkedin.com/in/juanpablobarea/',
    twitterUrl: 'https://twitter.com/ConoCripto',
    discordUrl: 'Jotape Barea#3643',
    bio: `Degree in Information Systems. Juan Pablo is passionate about technology, innovation and bitcoiner. He participated in several projects as a business developer and consultant at first class companies. s. Since 2013 promoting the use of Bitcoin in communities.
    Juan Pablo pursued detail, deliverables quality, and satisfaction aligned with a strategic vision and in turn combined with strong interpersonal communication skills and empathy.
    `
  },
  {
    name: 'Maximiliano Marino',
    role: 'Community Leader & Spanish Brand Ambassador',
    img: membersSlide5,
    linkedinUrl:
      'https://www.linkedin.com/in/juanpablobarea/',
    twitterUrl: 'https://twitter.com/MaxMarino95',
    discordUrl: 'MaxMarino#4957',
    bio: `Degree in Human Resources. Maximiliano was always interested in human relations, to achieve a common goal as a team. His first experiences as team and community leader, before becoming a professional, included videogame clans, video game developing, translation and projects.
    Maximiliano is always thinking of new ways and strategies to involve their community members in the project, strongly believing that people need to feel cared by the project they're supporting to be satisfied with it.
    `
  },
  {
    name: 'Santiago Schwarzstein',
    role: 'Community Developer & Growth',
    img: membersSlide6,
    linkedinUrl:
      'https://www.linkedin.com/in/santiagoschw/',
    twitterUrl: 'https://twitter.com/SantiagoSchw_',
    discordUrl: 'SantiagoSchw#0466',
    bio: `Santiago is a Digital Marketer by day and an independent writer by night. With a BA in Communications, he strives to highlight the fascinating in the mundane through engaging content and powerful words. Living in Argentina, with high inflation and stuck with a weak national currency, Santiago first discovered crypto looking to preserve his savings. He immediately fell in love with the industry and the philosophy behind it. He started a personal blog where he shared his thoughts and experience in the industry, and soon after pursued a career in the field. He now works in the cryptocurrency industry full-time and as an independent consultant for many different web3 projects.`
  }
]

/*

Linkedin:
Twitter:  @SantiagoSchw_
Discord: SantiagoSchw#0466

Santiago is a Digital Marketer by day and an independent writer by night. With a BA in Communications, he strives to highlight the fascinating in the mundane through engaging content and powerful words. Living in Argentina, with high inflation and stuck with a weak national currency, Santiago first discovered crypto looking to preserve his savings. He immediately fell in love with the industry and the philosophy behind it. He started a personal blog where he shared his thoughts and experience in the industry, and soon after pursued a career in the field. He now works in the cryptocurrency industry full-time and as an independent consultant for many different web3 projects.

*/
