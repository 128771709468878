import { ClientsPartnerCard } from 'view/components/ClientsPartnerCard'

import {
  IPartnershipCardData,
  partnershipsSliderData
} from '../../components/HomePartnershipsSlider/constants'
import styles from './ClientsPartnershipsCardSection.module.css'

export const ClientsPartnershipsCardSection = () => {
  return (
    <section className={styles.sectionContainer}>
      {partnershipsSliderData.map(
        (item: IPartnershipCardData, index) => (
          <ClientsPartnerCard
            website={item.website}
            description={item.description}
            imageUrl={item.imageUrl}
            name={item.name}
            key={index}
          />
        )
      )}
    </section>
  )
}
