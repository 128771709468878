import { useLockedBody } from 'lib/hooks'
import { NavItem } from 'lib/interfaces'
import React, { FC, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import styles from './HeaderHamburger.module.css'

interface Props {
  navItems: NavItem[]
}

export const HeaderHamburger: FC<Props> = ({
  navItems
}) => {
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null)
  const sidemenuRef = useRef<HTMLDivElement>(null)
  const { isLocked, toggleLockBody } = useLockedBody()

  const handleActive = (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation()
    console.log('entra')
    toggleLockBody()
  }

  useEffect(() => {
    if (hamburgerButtonRef.current && sidemenuRef.current) {
      if (isLocked) {
        hamburgerButtonRef.current.classList.add(
          styles.isActive
        )
        sidemenuRef.current.classList.add(styles.isVisible)
      } else {
        hamburgerButtonRef.current.classList.remove(
          styles.isActive
        )
        sidemenuRef.current.classList.remove(
          styles.isVisible
        )
      }
    }
  }, [isLocked])

  return (
    <>
      <div className={styles.container}>
        <button
          onClick={handleActive}
          className={styles.hamburger}
          id='hamburger'
          ref={hamburgerButtonRef}
        >
          <span className={styles.line}></span>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
        </button>
      </div>
      <div
        ref={sidemenuRef}
        className={styles.sidemenu}
      >
        <div className={styles.navContainer}>
          {navItems.map((item, index) => (
            <Link
              to={item.route}
              className={styles.navItem}
              key={index}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}
