import { useWindowSize } from 'lib/hooks'
import { FC } from 'react'
import { Pagination } from 'swiper'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { B2bServicesSlide } from 'view/components'

import styles from './B2bServicesSlider.module.css'
import { cardsData } from './constants'

export const B2bServicesSlider: FC = () => {
  const { windowSize } = useWindowSize()
  return (
    <>
      <Swiper
        slidesPerView={
          windowSize.width && windowSize.width < 1250
            ? 1
            : 3
        }
        spaceBetween={0}
        pagination={{
          clickable: true,
          enabled: true,
          horizontalClass: styles.swiperPagination,
          type: 'bullets'
        }}
        modules={[Pagination]}
        className={styles.container}
      >
        {cardsData.map((c, index) => (
          <SwiperSlide
            key={index}
            className={styles.slideContent}
          >
            <B2bServicesSlide
              targetId={c.targetId}
              buttonText={c.buttonText}
              text={c.text}
              title={c.title ?? 'asd'}
              isDisabled={c.disabled}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
