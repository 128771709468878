import { FC, FormEvent, useRef } from 'react'
import { Button } from 'view/elements'

import emailjs from '@emailjs/browser'

import styles from './AboutBrandbookForm.module.css'

const SERVICE_ID = 'service_fqul4b5'
const TEMPLATE_ID = 'template_yb768fu'
const EMAILJS_PUBLIC_KEY = '0WZebjj-mU_mbw7xt'

type FormElement = HTMLFormElement | null

export const AboutBrandbookForm: FC = () => {
  const form = useRef<FormElement>(null)

  const sendEmail = (
    e: FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault()
    if (form.current) {
      // eslint-disable-next-line no-unused-vars
      console.log(form.current)
      const formElement: HTMLFormElement = form.current
      emailjs
        .sendForm(
          SERVICE_ID,
          TEMPLATE_ID,
          form.current,
          EMAILJS_PUBLIC_KEY
        )
        .then(
          result => {
            if (form.current && result) {
              console.log(result.text)
              form.current.reset()
            }
          },
          error => {
            console.log(error.text)
          }
        )
    }
  }

  return (
    <form
      className={styles.formContainer}
      ref={form}
      onSubmit={sendEmail}
    >
      <input
        className={styles.formInput}
        type='text'
        placeholder='Name'
        name='username'
      />
      <input
        className={styles.formInput}
        type='text'
        placeholder='Email'
        autoComplete='off'
        name='user_email'
      />
      <div>
        <Button
          type='submit'
          variant='default'
        >
          Brandbook
        </Button>
      </div>
    </form>
  )
}
