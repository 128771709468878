import { SVGProps } from 'react'

export const YoutubeIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width={22}
    height={22}
    {...props}
  >
    <path d='M21.582 6.186a2.506 2.506 0 0 0-1.768-1.768C18.254 4 12 4 12 4s-6.254 0-7.814.418c-.86.23-1.538.908-1.768 1.768C2 7.746 2 12 2 12s0 4.254.418 5.814c.23.86.908 1.538 1.768 1.768C5.746 20 12 20 12 20s6.254 0 7.814-.418a2.504 2.504 0 0 0 1.768-1.768C22 16.254 22 12 22 12s0-4.254-.418-5.814zM10 14.598V9.402a.5.5 0 0 1 .75-.433l4.5 2.598a.5.5 0 0 1 0 .866l-4.5 2.598a.5.5 0 0 1-.75-.433z' />
  </svg>
)
