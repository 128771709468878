import { SVGProps } from 'react'

export const LintrFooterIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={17}
    height={20}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g
      clipPath='url(#a)'
      fill='#fff'
    >
      <path d='M17 9.537h-5.059c.013.143.136.2.218.278 1.025.959 2.048 1.919 3.086 2.865.21.191.256.3.017.513-.526.466-1.026.959-1.52 1.455-.179.18-.282.205-.482.01-1.496-1.463-3.01-2.909-4.506-4.371-.196-.192-.292-.176-.48.006-1.498 1.462-3.012 2.907-4.51 4.369-.194.19-.297.182-.482-.004a39.775 39.775 0 0 0-1.569-1.502c-.203-.184-.16-.277.017-.44 1.035-.949 2.058-1.909 3.088-2.863.09-.084.197-.151.33-.25-.182-.105-.308-.075-.425-.076-1.462-.002-2.925-.007-4.388.004-.25.002-.34-.053-.334-.312C.02 8.5.02 7.776 0 7.054c-.007-.263.09-.31.337-.309 1.463.01 2.925.005 4.388.005h.436c-.25-.225-.431-.386-.61-.55-.937-.854-1.869-1.714-2.814-2.56-.173-.155-.194-.244-.015-.413a45.249 45.249 0 0 0 1.544-1.525c.196-.204.307-.24.531-.012.942.958 1.91 1.894 2.867 2.84.086.086.156.187.233.28l.086-.045c.005-.115.016-.23.016-.346C7 3.053 7.006 1.687 6.995.321c-.002-.24.053-.327.322-.321.794.018 1.59.02 2.384-.001.291-.007.335.098.333.343-.01 1.366-.005 2.733-.005 4.098v.386c.262-.157.412-.35.583-.517.904-.886 1.809-1.772 2.701-2.67.168-.169.252-.139.4.011.526.53 1.058 1.054 1.603 1.564.179.168.165.26-.01.418-1.026.925-2.041 1.861-3.062 2.791-.091.083-.196.153-.294.229l.04.086H17v2.799ZM10.028 19.934l-.007.066h-2.97v-.067c.111-.098.25-.105.388-.106.734-.004 1.468-.004 2.202 0 .139 0 .275.014.388.107Z' />
      <path d='M10.028 19.934H7.051c-.004-2.084-.007-4.167-.016-6.25 0-.18.074-.226.245-.225.839.005 1.678.005 2.517 0 .173-.001.246.046.246.226-.009 2.083-.011 4.167-.015 6.25Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path
          fill='#fff'
          d='M0 0h17v20H0z'
        />
      </clipPath>
    </defs>
  </svg>
)
