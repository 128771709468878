export const aboutHeadImage = '/aboutHeadImage.png'
export const homeWelcomeImage = '/homeWelcomeImage.png'
export const communityHeadImage = '/communityHeadImage.png'

// FIGURES

export const homeB2bFigure = '/figures/homeB2bFigure.png'
export const homeOurCommunityFigure =
  '/figures/homeCommunityFigure.png'
export const communityMembersFigure =
  '/figures/communityMembersFigure.png'
export const communitySocialFigure =
  '/figures/communitySocialFigure.png'
export const communityDiscordFigure =
  '/figures/communityDiscordFigure.png'
export const communityBusinessValueFigure =
  '/figures/communityBusinessValueFigure.png'
export const ourClientsHeadFigure =
  '/figures/ourClientsHeadFigure.png'
export const b2bBussinessServicesFigure =
  '/figures/b2bBussinessServicesFigure.png'
export const b2bConsultancyFigure =
  '/figures/b2bConsultancyFigure.png'
export const b2bDevelopmentFigure =
  '/figures/b2bDevelopmentFigure.png'
export const b2bResearchingFigure =
  '/figures/b2bResearchingFigure.png'
export const aboutB2bCardFigure =
  '/figures/aboutB2bCardFigure.png'

// PARTNERS SLIDER

export const buenbitLogo = '/logos/buenbitLogo.png'
export const globantLogo = '/logos/globantLogo.png'
export const hpLogo = '/logos/hpLogo.png'
export const applicaCorpLogo = '/logos/applicaCorpLogo.png'
export const mithrandirtokenLogo =
  '/logos/mithrandirtokenLogo.png'
export const otherlabsLogo = '/logos/otherlabsLogo.png'
export const perficientLogo = '/logos/perficientLogo.png'
export const wesitLogo = '/logos/wesitLogo.png'
export const endavaLogo = '/logos/endavaLogo.png'

// MEMBERS SLIDER

export const membersSlide1 = '/img/aboutMembersSlide_1.jpeg'
export const membersSlide2 = '/img/aboutMembersSlide_2.png'
export const membersSlide3 = '/img/aboutMembersSlide_3.png'

export const membersSlide5 = '/img/aboutMembersSlide_5.png'
export const membersSlide6 = '/img/aboutMembersSlide_6.jpg'
