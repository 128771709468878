import { useWindowSize } from 'lib/hooks'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { HeaderHamburger } from 'view/components'
import { SVG } from 'view/elements'

import styles from './Header.module.css'
import { navItems } from './constants'

export const Header: FC = () => {
  const { isMobile } = useWindowSize()
  return (
    <header className={styles.headerContainer}>
      <Link to='/'>
        <div className={styles.logoContainer}>
          <SVG variant='header-logo' />
        </div>
      </Link>
      {isMobile ? (
        <div className={styles.mobileNavIcon}>
          <HeaderHamburger navItems={navItems} />
        </div>
      ) : (
        <div className={styles.navbarContainer}>
          {navItems.map((item, index) => (
            <Link
              to={item.route}
              className={styles.navItem}
              key={index}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </header>
  )
}
