import { useNewWindow } from 'lib/hooks'
import { EXTERNAL_URL_DATA } from 'lib/utils/constants/externalUrls'
import {
  homeB2bFigure,
  homeOurCommunityFigure,
  homeWelcomeImage
} from 'lib/utils/constants/imageUrls'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, PageContentWrapper } from 'view/elements'
import { MainLayout } from 'view/layouts'
import {
  HomeLeftFigureSection,
  HomePartnershipsSection,
  HomeRightFigureSection
} from 'view/prototypes'

import styles from './HomePage.module.css'

const b2bServicesText = `
  We offer a Research, Consulting and
  Development service for traditional
  companies who are looking to adopt the
  blockchain, and web3 ecosystem into
  their process, products & services.`
const ourCommunityText = `We are also a public Web3
community that fosters networking among our members
through the generation and exchange of business value,
 partnerships and collaborations.`

export const HomePage: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0) // Hace scroll hasta la parte superior de la página
  }, [])
  return (
    <MainLayout>
      <div className={styles.homePageBackground}>
        <div className={styles.welcomeContainer}>
          <div className={styles.imageContainer}>
            <img
              src={homeWelcomeImage}
              className={styles.homeWelcomeImage}
              alt='Welcome to Innovatio Space'
            />
            <div className={styles.welcomeContent}>
              <h3>
                Welcome to <strong>Innovatio Space</strong>
              </h3>
              <h1>Be part of the Web3</h1>
            </div>
          </div>
        </div>
        <PageContentWrapper>
          <div className={styles.sectionsGap}>
            <HomeLeftFigureSection
              imageSource={homeB2bFigure}
              sectionText={b2bServicesText}
              sectionTitle={'B2B Services'}
            >
              <Button
                onClick={() => navigate('/b2b-services')}
                variant='default'
              >
                View more
              </Button>
            </HomeLeftFigureSection>
            <HomeRightFigureSection
              imageSource={homeOurCommunityFigure}
              sectionText={ourCommunityText}
              sectionTitle={'Our Community'}
            >
              <Button
                onClick={() => navigate('/community')}
                variant='default'
              >
                View more
              </Button>
              <Button
                onClick={() =>
                  useNewWindow(EXTERNAL_URL_DATA.discord)
                }
                variant='outlined'
              >
                Join Us
              </Button>
            </HomeRightFigureSection>
          </div>
        </PageContentWrapper>
        <HomePartnershipsSection />
      </div>
    </MainLayout>
  )
}
