import { NavItem } from 'lib/interfaces'

export const navItems: NavItem[] = [
  {
    name: 'About Us',
    route: '/about-us'
  },
  {
    name: 'B2B Services',
    route: '/b2b-services'
  },
  {
    name: 'Our Community',
    route: '/community'
  },
  {
    name: 'Our Clients',
    route: '/clients'
  }
]
