import { IAboutSliderData } from 'lib/interfaces'
import { FC } from 'react'
import {
  EffectFade,
  Navigation,
  Pagination,
  SwiperOptions
} from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import { AboutMembersSlide } from '../AboutMembersSlide'
import styles from './AboutMembersSlider.module.css'
import { aboutSliderData } from './constants'

const swiperSharedOptions: SwiperOptions = {
  pagination: {
    type: 'progressbar',
    clickable: true,
    enabled: true,
    horizontalClass: styles.swiperPagination
  },
  initialSlide: 0
}

export const AboutMemberSlider: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Swiper
        allowSlideNext={true}
        allowSlidePrev={true}
        spaceBetween={10}
        allowTouchMove={true}
        centeredSlides={true}
        slidesPerGroup={1}
        breakpoints={{
          1250: {
            slidesPerView: 3,
            width: 1250,
            ...swiperSharedOptions
          },
          800: {
            width: 850,
            slidesPerView: 2,
            spaceBetween: 0,
            ...swiperSharedOptions
          },
          300: {
            width: 450,
            slidesPerView: 1,
            spaceBetween: 0,
            ...swiperSharedOptions
          },
          1: {
            width: 0,
            slidesPerView: 1,
            spaceBetween: 0,
            ...swiperSharedOptions
          }
        }}
        initialSlide={0}
        pagination={{
          type: 'progressbar',
          clickable: true,
          enabled: true,
          horizontalClass: styles.swiperPagination
        }}
        modules={[Pagination, Navigation, EffectFade]}
        className={styles.container}
      >
        {aboutSliderData.map(
          (item: IAboutSliderData, index) => (
            <SwiperSlide
              className={styles.slideContent}
              key={index}
            >
              <AboutMembersSlide
                bio={item.bio}
                discordUrl={item.discordUrl}
                img={item.img}
                linkedinUrl={item.linkedinUrl}
                name={item.name}
                role={item.role}
                twitterUrl={item.twitterUrl}
                key={index}
              />
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  )
}
