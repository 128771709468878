import { FC, ReactNode } from 'react'
import { SwiperSlide as Slide } from 'swiper/react'

interface Props {
  children: ReactNode
}

export const SwiperSlide: FC<Props> = ({ children }) => (
  <Slide>{children}</Slide>
)
