import { FC, ReactNode, useRef } from 'react'

import styles from './B2bLeftFigureSection.module.css'

interface Props {
  title: string
  text: string | string[]
  image: string
  children: ReactNode
  targetId: string
}

export const B2bLeftFigureSection: FC<Props> = ({
  children,
  title,
  image,
  text,
  targetId
}) => {
  return (
    <section
      className={styles.sectionContainer}
      id={targetId}
    >
      <div className={styles.leftSlotContainer}>
        <img
          src={image}
          alt=''
        />
      </div>
      <div className={styles.rightSlotContainer}>
        <h3>{title}</h3>
        <div>
          {typeof text === 'string' ? (
            <p>{text}</p>
          ) : (
            text.map((paragraph, index) => {
              if (index === text.length - 1) {
                return <p key={index}>{paragraph}</p>
              }
              return (
                <>
                  <p key={index}>{paragraph}</p>
                  <br />
                  <br />
                </>
              )
            })
          )}
        </div>

        <div className={styles.childrenContainer}>
          {children}
        </div>
      </div>
    </section>
  )
}
