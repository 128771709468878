import { SVGProps } from 'react'

export const DiscordIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width='21'
    height='15'
    viewBox='0 0 21 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.2505 1.81165C16.8767 0.651493 15.1348 0.00995025 13.3315 0V0.499729C13.3315 0.499729 15.2058 0.391845 17.527 2.66048C15.4281 1.33112 12.9904 0.624871 10.501 0.624871C8.01161 0.624871 5.574 1.33112 3.47506 2.66048C5.79518 0.391845 7.67056 0.499729 7.67056 0.499729V0C5.86728 0.00995025 4.12536 0.651493 2.75156 1.81165C0.146543 5.14388 0 12.4322 0 12.4322C0.647945 13.2687 1.48896 13.9381 2.4521 14.3839C3.41523 14.8298 4.47221 15.039 5.53386 14.994L6.66114 13.3655C5.4454 13.0156 4.35813 12.3226 3.53143 11.3707C5.61869 12.6744 8.03427 13.3661 10.5 13.3661C12.9657 13.3661 15.3813 12.6744 17.4686 11.3707C16.6418 12.3226 15.5546 13.0156 14.3389 13.3655L15.4661 14.994C16.5278 15.0391 17.5848 14.8299 18.548 14.3841C19.5111 13.9382 20.3521 13.2687 21 12.4322C21 12.4322 20.8555 5.14388 18.2505 1.81165ZM7.22476 10.3712C6.84542 10.394 6.46791 10.3032 6.14118 10.1104C5.81444 9.91764 5.55354 9.63183 5.3923 9.29004C5.23106 8.94825 5.17689 8.56624 5.23685 8.19352C5.29681 7.8208 5.46812 7.47456 5.72856 7.19968C5.98901 6.9248 6.32659 6.73397 6.69754 6.6519C7.06849 6.56983 7.45571 6.60031 7.80901 6.7394C8.16231 6.87849 8.46539 7.11978 8.67898 7.43198C8.89256 7.74418 9.00679 8.1129 9.00687 8.49035C9.02014 8.97405 8.84019 9.44333 8.5063 9.79572C8.17241 10.1481 7.7117 10.355 7.22476 10.3712ZM13.767 10.3712C13.3877 10.394 13.0102 10.3032 12.6834 10.1104C12.3567 9.91764 12.0958 9.63183 11.9346 9.29004C11.7733 8.94825 11.7192 8.56624 11.7791 8.19352C11.8391 7.8208 12.0104 7.47456 12.2708 7.19968C12.5313 6.9248 12.8689 6.73397 13.2398 6.6519C13.6108 6.56983 13.998 6.60031 14.3513 6.7394C14.7046 6.87849 15.0077 7.11978 15.2212 7.43198C15.4348 7.74418 15.5491 8.1129 15.5491 8.49035C15.5624 8.97405 15.3825 9.44333 15.0486 9.79572C14.7147 10.1481 14.254 10.355 13.767 10.3712Z'
      fill='#FAFAFA'
    />
  </svg>
)
