import { SVGProps } from 'react'

export const Welcome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={604}
    height={499}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M570.241 311.893H528.55a1.996 1.996 0 0 0-1.995 1.995v12.767c0 1.101.894 1.995 1.995 1.995h2.713c-.989 1.772-2.538 4.628-2.155 4.628.431 0 4.789-3.144 6.8-4.628h34.317a1.996 1.996 0 0 0 1.996-1.995v-12.767a1.996 1.996 0 0 0-1.996-1.995h.016ZM559.515 336.183h41.676c1.101 0 1.995.894 1.995 1.995v12.767a1.995 1.995 0 0 1-1.995 1.995h-2.714c.99 1.772 2.538 4.628 2.155 4.628-.431 0-4.788-3.144-6.8-4.628h-34.317a2.01 2.01 0 0 1-2.011-1.995v-12.767a2 2 0 0 1 2.011-1.995v0ZM559.515 285.656h41.676c1.101 0 1.995.894 1.995 1.995v12.767a1.996 1.996 0 0 1-1.995 1.995h-2.714c.99 1.772 2.538 4.628 2.155 4.628-.431 0-4.788-3.144-6.8-4.628h-34.317c-1.101 0-2.011-.894-2.011-1.995v-12.767a2 2 0 0 1 2.011-1.995v0ZM62.25 210.886c1.133-6.718-3.879-11.57-9.737-11.522-5.698.048-10.742 9.496-10.742 9.496s-6.783-6.767-12.721-7.374c-6.177-.622-11.397 5.873-8.603 14.348 4.038 12.241 22.585 20.252 22.585 20.252s16.951-11.682 19.234-25.216l-.016.016ZM22.027 234.618c-.83-3.511-4.262-4.852-7.119-3.639-2.777 1.197-3.304 6.847-3.304 6.847s-4.709-1.915-7.725-.99c-3.145.974-4.374 5.203-1.261 8.778 4.485 5.155 15.195 5.266 15.195 5.266s5.89-9.192 4.23-16.262h-.016ZM32.258 242.518c-16.025 16.454-5.507 39.371 11.301 39.084 19.042-.319 17.51-31.232 8.555-16.997-8.954 14.236 13.472 38.335 25.236 24.434'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M402.071 92.9c0-38.606-41.596-69.903-92.912-69.903-51.317 0-92.896 31.297-92.896 69.902 0 36.387 36.967 66.279 84.181 69.583l8.731 22.774 8.731-22.774c47.214-3.304 84.181-33.196 84.181-69.583h-.016Z'
      fill='#152120'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M315.735 82.095V70.987l-6.097-.479a24.721 24.721 0 0 0-2.746-6.623l3.975-4.644-7.853-7.868-4.645 3.974a24.714 24.714 0 0 0-6.624-2.745l-.479-6.097h-11.109l-.479 6.097a24.206 24.206 0 0 0-6.624 2.745l-4.645-3.974-7.869 7.868 3.974 4.644a24.719 24.719 0 0 0-2.745 6.623l-6.097.479v11.108l6.097.478a24.718 24.718 0 0 0 2.745 6.624l-3.974 4.66 7.869 7.852 4.645-3.974a24.717 24.717 0 0 0 6.624 2.745l.479 6.096h11.109l.479-6.096a24.213 24.213 0 0 0 6.624-2.745l4.645 3.974 7.853-7.852-3.975-4.66a24.72 24.72 0 0 0 2.746-6.624l6.097-.478v0Zm-30.04 5.139c-5.905 0-10.694-4.788-10.694-10.693 0-5.905 4.789-10.693 10.694-10.693 5.906 0 10.695 4.788 10.695 10.693 0 5.905-4.789 10.693-10.695 10.693Z'
      fill='#EF9C00'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M362.694 95.74v-8.602l-4.725-.367a19.073 19.073 0 0 0-2.123-5.123l3.065-3.591-6.082-6.08-3.591 3.064a19.077 19.077 0 0 0-5.124-2.123l-.383-4.708h-8.587l-.367 4.708a19.09 19.09 0 0 0-5.124 2.123l-3.591-3.064-6.081 6.08 3.064 3.59a19.105 19.105 0 0 0-2.123 5.124l-4.708.367v8.602l4.708.367a19.323 19.323 0 0 0 2.123 5.123l-3.064 3.591 6.081 6.08 3.591-3.064a19.085 19.085 0 0 0 5.124 2.123l.367 4.708h8.587l.383-4.708a19.288 19.288 0 0 0 5.124-2.123l3.591 3.064 6.082-6.08-3.065-3.591a19.075 19.075 0 0 0 2.123-5.123l4.725-.367Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.5}
      d='M362.694 95.74v-8.602l-4.725-.367a19.073 19.073 0 0 0-2.123-5.123l3.065-3.591-6.082-6.08-3.591 3.064a19.077 19.077 0 0 0-5.124-2.123l-.383-4.708h-8.587l-.367 4.708a19.09 19.09 0 0 0-5.124 2.123l-3.591-3.064-6.081 6.08 3.064 3.59a19.105 19.105 0 0 0-2.123 5.124l-4.708.367v8.602l4.708.367a19.323 19.323 0 0 0 2.123 5.123l-3.064 3.591 6.081 6.08 3.591-3.064a19.085 19.085 0 0 0 5.124 2.123l.367 4.708h8.587l.383-4.708a19.288 19.288 0 0 0 5.124-2.123l3.591 3.064 6.082-6.08-3.065-3.591a19.075 19.075 0 0 0 2.123-5.123l4.725-.367Zm-23.24 3.974a8.27 8.27 0 0 1-8.268-8.267 8.27 8.27 0 0 1 16.536 0 8.27 8.27 0 0 1-8.268 8.267Z'
      fill='#fff'
    />
    <path
      d='M362.694 95.74v-8.602l-4.725-.367a19.073 19.073 0 0 0-2.123-5.123l3.065-3.591-6.082-6.08-3.591 3.064a19.077 19.077 0 0 0-5.124-2.123l-.383-4.708h-8.587l-.367 4.708a19.09 19.09 0 0 0-5.124 2.123l-3.591-3.064-6.081 6.08 3.064 3.59a19.105 19.105 0 0 0-2.123 5.124l-4.708.367v8.602l4.708.367a19.323 19.323 0 0 0 2.123 5.123l-3.064 3.591 6.081 6.08 3.591-3.064a19.085 19.085 0 0 0 5.124 2.123l.367 4.708h8.587l.383-4.708a19.288 19.288 0 0 0 5.124-2.123l3.591 3.064 6.082-6.08-3.065-3.591a19.075 19.075 0 0 0 2.123-5.123l4.725-.367v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M339.47 99.714a8.27 8.27 0 0 1-8.268-8.267 8.27 8.27 0 0 1 16.536 0 8.27 8.27 0 0 1-8.268 8.267Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M322.183 125.68v-6.177l-3.383-.271a13.656 13.656 0 0 0-1.533-3.687l2.203-2.585-4.374-4.373-2.585 2.203a13.973 13.973 0 0 0-3.687-1.533l-.272-3.383h-6.177l-.271 3.383a13.642 13.642 0 0 0-3.687 1.533l-2.586-2.203-4.374 4.373 2.203 2.585a13.957 13.957 0 0 0-1.532 3.687l-3.384.271v6.177l3.384.271a13.653 13.653 0 0 0 1.532 3.687l-2.203 2.585 4.374 4.373 2.586-2.203a14.171 14.171 0 0 0 3.687 1.533l.271 3.383h6.177l.272-3.383a13.669 13.669 0 0 0 3.687-1.533l2.585 2.203 4.374-4.373-2.203-2.585a13.446 13.446 0 0 0 1.533-3.687l3.383-.271Z'
      fill='#EF9C00'
    />
    <path
      d='M305.488 128.536a5.953 5.953 0 1 1-.001-11.907 5.953 5.953 0 0 1 .001 11.907Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.7}
      d='M322.183 125.68v-6.177l-3.383-.271a13.656 13.656 0 0 0-1.533-3.687l2.203-2.585-4.374-4.373-2.585 2.203a13.973 13.973 0 0 0-3.687-1.533l-.272-3.383h-6.177l-.271 3.383a13.642 13.642 0 0 0-3.687 1.533l-2.586-2.203-4.374 4.373 2.203 2.585a13.957 13.957 0 0 0-1.532 3.687l-3.384.271v6.177l3.384.271a13.653 13.653 0 0 0 1.532 3.687l-2.203 2.585 4.374 4.373 2.586-2.203a14.171 14.171 0 0 0 3.687 1.533l.271 3.383h6.177l.272-3.383a13.669 13.669 0 0 0 3.687-1.533l2.585 2.203 4.374-4.373-2.203-2.585a13.446 13.446 0 0 0 1.533-3.687l3.383-.271Zm-16.695 2.856a5.953 5.953 0 1 1-.001-11.907 5.953 5.953 0 0 1 .001 11.907Z'
      fill='#fff'
    />
    <path
      d='M322.183 125.68v-6.177l-3.383-.271a13.656 13.656 0 0 0-1.533-3.687l2.203-2.585-4.374-4.373-2.585 2.203a13.973 13.973 0 0 0-3.687-1.533l-.272-3.383h-6.177l-.271 3.383a13.642 13.642 0 0 0-3.687 1.533l-2.586-2.203-4.374 4.373 2.203 2.585a13.957 13.957 0 0 0-1.532 3.687l-3.384.271v6.177l3.384.271a13.653 13.653 0 0 0 1.532 3.687l-2.203 2.585 4.374 4.373 2.586-2.203a14.171 14.171 0 0 0 3.687 1.533l.271 3.383h6.177l.272-3.383a13.669 13.669 0 0 0 3.687-1.533l2.585 2.203 4.374-4.373-2.203-2.585a13.446 13.446 0 0 0 1.533-3.687l3.383-.271Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M305.488 128.536a5.953 5.953 0 1 1-.001-11.907 5.953 5.953 0 0 1 .001 11.907Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m457.266 325.714-11.333 8.634c-17.653-6.799-16.52-8.331-33.407-10.773-8.747-1.261-26.864-.734-42.091.064-13.759.702-25.171 1.628-25.171 1.628s33.663-33.451 42.92-38.414c9.114-4.868 49.912-22.375 51.189-22.918h.032s30.63 34.121 17.861 61.795v-.016Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.2}
      d='m457.266 325.714-11.333 8.634c-17.653-6.799-16.52-8.331-33.407-10.773-8.747-1.261-26.864-.734-42.091.064 18.946-13.103 70.183-2.362 70.183-2.362s-7.933-10.102-8.555-30.578c-.607-19.71 6.751-26.285 7.31-26.764h.032s30.63 34.121 17.861 61.795v-.016Z'
      fill='#000'
    />
    <path
      d='M345.264 325.267s33.663-33.451 42.92-38.414c9.258-4.948 51.221-22.95 51.221-22.95s30.614 34.121 17.861 61.795l-11.333 8.634'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m507.05 273.128-51.428 46.681c-24.134-20.54-16.361-51.469-16.361-51.469l18.484-12.975 22.314-15.672s12.099 5.554 20.447 13.661c5.825 5.682 9.816 12.608 6.544 19.774Z'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M500.506 253.354c-.735 2.362-2.411 4.756-5.316 7.15-16.632 13.773-30.231 3.351-37.445-5.139l22.314-15.672s12.099 5.554 20.447 13.661Z'
      fill='#EF9C00'
    />
    <path
      d='m507.05 273.128-51.428 46.681c-24.134-20.54-16.361-51.469-16.361-51.469l18.484-12.975 22.314-15.672s12.099 5.554 20.447 13.661c5.825 5.682 9.816 12.608 6.544 19.774v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M486.3 267.925s-8.46-1.915-14.653-13.885c-6.193-11.985-5.475-68.337-4.086-79.509 1.389-11.171 22.793-17.316 23.799-6.08.159 1.707.814 5.059 1.787 9.575 1.277 5.793 3.065 13.502 4.98 22.024 6.449 28.551 14.382 66.359 8.923 73.078'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M498.127 200.05c-9.465-7.006-16.169-20.189-16.169-20.189l11.189-1.835c1.277 5.793 3.065 13.502 4.98 22.024Z'
      fill='#EF9C00'
    />
    <path
      d='M486.3 267.925s-8.46-1.915-14.653-13.885c-6.193-11.985-5.475-68.337-4.086-79.509 1.389-11.171 22.793-17.316 23.799-6.08.159 1.707.814 5.059 1.787 9.575 1.277 5.793 3.065 13.502 4.98 22.024 6.449 28.551 14.382 66.359 8.923 73.078'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M552.78 99.714s0 .127-.048.303c-.83 9.049-11.908 7.341-26.512 9.863-11.126 1.915-25.667 10.916-36.345 18.513-8.412 5.968-14.413 11.06-14.413 11.06s-12.259-8.156-23.336-30.834c0 0 40.095-25.168 44.772-56.304 3.687-24.418 39.074-.495 52.386 32.892 2.65 6.623 3.719 11.251 3.496 14.507Z'
      fill='#fff'
    />
    <path
      opacity={0.15}
      d='M552.732 100.017c-.83 9.049-11.908 7.341-26.512 9.863-11.126 1.915-25.667 10.916-36.345 18.513-4.868-2.873-7.55-5.65-6.464-8.06 4.932-10.932 23.479-14.443 23.479-14.443s-1.58-7.166 1.309-10.629c2.889-3.479 13.519 3.463 28.683 8.858 13.615 4.851 15.594-2.522 15.85-4.102Z'
      fill='#000'
    />
    <path
      d='M552.78 99.714s0 .127-.048.303c-.83 9.049-11.908 7.341-26.512 9.863-11.126 1.915-25.667 10.916-36.345 18.513-8.412 5.968-14.413 11.06-14.413 11.06s-12.259-8.156-23.336-30.834c0 0 40.095-25.168 44.772-56.304 3.687-24.418 39.074-.495 52.386 32.892 2.65 6.623 3.719 11.251 3.496 14.507v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M511.008 72.535c9.817-3.718 13.599-10.373 15.483-8.873 1.899 1.5 8.699 6.463 7.598 9.352-1.102 2.889-9.929 13.134-10.806 14.954'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M506.443 46.505c-5.283.495-4.581 14.396 8.364 31.488 12.945 17.093 25.73 24.897 31.396 22.95 5.667-1.963 1.74-9.4 1.74-9.4'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M493.865 128.504s-24.628 18.593-29.848 18.417c-5.235-.159-12.386-.303-19.265-9.479-5.315-7.102-4.533-13.231-3.001-17.556.431-1.276.942-2.378 1.373-3.319 1.069-2.33 21.436-22.838 21.436-22.838l5.06 1.34s3.288 18.402 23.336 31.169l.909 2.266Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.2}
      d='M493.865 128.504s-24.628 18.593-29.848 18.417c-5.235-.159-12.386-.303-19.265-9.479-5.315-7.102-4.533-13.231-3.001-17.556 1.277 4.996 3.735 12.161 7.678 15.002 6.304 4.565 32.529-10.102 32.529-10.102s-14.557-17.922-12.338-29.716c0 0 3.288 18.401 23.336 31.168l.909 2.266Z'
      fill='#000'
    />
    <path
      d='M493.865 128.504s-24.628 18.593-29.848 18.417c-5.235-.159-12.386-.303-19.265-9.479-5.315-7.102-4.533-13.231-3.001-17.556.431-1.276.942-2.378 1.373-3.319 1.069-2.33 21.436-22.838 21.436-22.838l5.06 1.34s3.288 18.402 23.336 31.169l.909 2.266v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity={0.15}
      d='M506.443 46.505c-5.283.495-4.581 14.396 8.364 31.488 12.945 17.093 25.73 24.897 31.396 22.95 5.667-1.963 1.74-9.4 1.74-9.4s-3.495-14.651-16.105-28.105c-14.764-15.768-25.395-16.933-25.395-16.933Z'
      fill='#000'
    />
    <path
      d='M513.658 157.295c-.575 7.086-19.489 32.892-31.7 27.306Z'
      fill='#fff'
    />
    <path
      d='M513.658 157.295c-.575 7.086-19.489 32.892-31.7 27.306'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M467.784 144.831s9.721 27.083 16.76 32.046c7.023 4.979 25.187-10.134 23.543-12.704-1.628-2.553-19.186-18.863-21.963-32.11 0 0-8.268 1.149-18.34 12.768Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.2}
      d='M467.784 144.831s9.721 27.083 16.76 32.046c7.023 4.979 25.187-10.134 23.543-12.704-1.628-2.553-19.186-18.863-21.963-32.11 0 0-8.268 1.149-18.34 12.768Z'
      fill='#000'
    />
    <path
      d='M467.784 144.831s9.721 27.083 16.76 32.046c7.023 4.979 25.187-10.134 23.543-12.704-1.628-2.553-19.186-18.863-21.963-32.11 0 0-8.268 1.149-18.34 12.768Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M513.259 159.561c1.66-5.586-2.394-4.069-1.74-9.32.654-5.251-2.729-5.235-3.783-10.645-1.069-5.41-4.405-4.883-6.353-9.352-1.947-4.469-6.831-2.681-11.556-.495-4.724 2.171-11.061 12.56-11.061 12.56-.942 6.671 4.645 5.283 4.645 5.283-3.703 6.862 2.474 8.362 2.474 8.362-.335 7.374 4.134 6.464 4.134 6.464s-.224 3.431 3.112 4.006c2.139.367 18.484-1.245 20.128-6.831v-.032Z'
      fill='#fff'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M483.411 147.576s9.018-9.528 11.779-10.613M485.885 155.938s5.443-6.096 14.493-9.16M490.003 162.386s11.971-5.905 13.727-6.448'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M474.169 183.341s-8.779-8.124-10.375-11.571c-1.596-3.447-4.677-18.289-2.474-22.279 2.203-3.99 8.651-10.629 9.657-13.821 1.005-3.192 6.576 3.798.207 16.566 0 0 7.63 9.24 6.385 19.343'
      fill='#fff'
    />
    <path
      d='M474.169 183.341s-8.779-8.124-10.375-11.571c-1.596-3.447-4.677-18.289-2.474-22.279 2.203-3.99 8.651-10.629 9.657-13.821 1.005-3.192 6.576 3.798.207 16.566 0 0 7.63 9.24 6.385 19.343'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity={0.15}
      d='M552.78 99.714s0 .127-.048.303c0-.112.032-.191.048-.303Z'
      fill='#000'
    />
    <path
      d='M468.95 419.57s-3.512 1.421-8.572-4.899c-9.625-12.033-24.022-40.936-33.487-54.788-4.054-5.937-15.259-11.778-27.374-16.678-18.739-7.565-39.648-12.911-39.648-12.911s50.981-3.144 63.925.75c11.205 3.352 50.886 26.046 62.203 32.573 1.931 1.117 3.032 1.756 3.032 1.756s1.708 24.034-20.079 54.197Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.2}
      d='M468.95 419.57s-3.512 1.421-8.572-4.899c-9.625-12.033-24.022-40.936-33.487-54.788-4.054-5.937-15.259-11.778-27.374-16.678 46.24 10.565 62.633 56.975 62.633 56.975s2.458-26.349 23.847-36.563c1.931 1.117 3.032 1.756 3.032 1.756s1.708 24.034-20.079 54.197Z'
      fill='#000'
    />
    <path
      d='M359.869 330.294s50.981-3.144 63.925.75c13.105 3.942 65.251 34.329 65.251 34.329s1.692 24.034-20.079 54.197'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M553.977 440.286c-2.634 1.787-7.678 1.771-14.078.59-5.874-1.085-12.881-3.144-20.192-5.681-24.501-8.491-52.386-22.28-52.386-22.28s.08-32.748 19.33-45.436c0 0 24.964 17.093 41.037 21.322 13.471 3.559 26.289 51.485 26.289 51.485Z'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M539.898 440.876c-5.873-1.085-12.88-3.144-20.191-5.681-3.591-16.582 6.848-35.015 6.848-35.015.399 17.667 7.246 31.663 13.343 40.696Z'
      fill='#EF9C00'
    />
    <path
      d='M553.977 440.286c-2.634 1.787-7.678 1.771-14.078.59-5.874-1.085-12.881-3.144-20.192-5.681-24.501-8.491-52.386-22.28-52.386-22.28s.08-32.748 19.33-45.436c0 0 24.964 17.093 41.037 21.322 13.471 3.559 26.289 51.485 26.289 51.485v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M530.992 430.838c-1.452-3.416-2.538-7.677-3.32-12.911Z'
      fill='#fff'
    />
    <path
      d='M530.992 430.838c-1.452-3.416-2.538-7.677-3.32-12.911'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M531.008 375.523c7.087 1.117 13.599 16.374 17.989 21.8 0 0 12.497 5.825 14.636 11.315 2.123 5.49 10.663 27.498-6.257 32.031-9.72 2.601-16.983 2.138-22.027-3.001'
      fill='#fff'
    />
    <path
      d='M531.008 375.523c7.087 1.117 13.599 16.374 17.989 21.8 0 0 12.497 5.825 14.636 11.315 2.123 5.49 10.663 27.498-6.257 32.031-9.72 2.601-16.983 2.138-22.027-3.001'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M532.604 353.834v.511c-.08 4.692-1.405 13.996-4.389 30.482-2.794 15.417-3.32 16.614-5.188 17.396-2.282.941-18.643 4.58-25.618 6.08-.575.112-1.085.224-1.516.319-1.006.224-3.656-1.165-3.48-2.17.83-5.091 6.512-51.118 8.795-52.618 3.463-2.266 25.857-4.405 28.347-3.718 1.166.319 3.049 2.521 3.049 3.718Z'
      fill='#999'
    />
    <path
      opacity={0.2}
      d='M532.604 354.345c-.08 4.692-1.405 13.996-4.389 30.482-2.794 15.417-3.32 16.614-5.188 17.396-2.282.941-18.643 4.58-25.618 6.08.846-6.655 3.4-25.247 8.667-50.719l26.528-3.239Z'
      fill='#000'
    />
    <path
      d='M532.604 353.834v.511c-.08 4.692-1.405 13.996-4.389 30.482-2.794 15.417-3.32 16.614-5.188 17.396-2.282.941-18.643 4.58-25.618 6.08-.575.112-1.085.224-1.516.319-1.006.224-3.656-1.165-3.48-2.17.83-5.091 6.512-51.118 8.795-52.618 3.463-2.266 25.857-4.405 28.347-3.718 1.166.319 3.049 2.521 3.049 3.718Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M497.09 405.031s7.454-43.361 8.108-46.01c.655-2.649 1.325-1.596 23.671-4.517'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M527.688 417.927s-11.572 4.133-10.774-2.921c0 0-2.666-1.867-2.666-4.788 0-2.92 1.868-6.112 1.868-6.112s-4.39 7.581-8.109 3.192c-.973-1.149 2.985-9.384 2.985-9.384s-3.655 2.202-5.379-1.915c-.527-1.261 5.571-12.592 6.896-13.438 4.469-2.809 16.105-8.81 20.095-6.528 3.99 2.283 5.315 9.464 7.71 12.114 2.394 2.649 1.069 11.698 0 18.081'
      fill='#fff'
    />
    <path
      d='M527.688 417.927s-11.572 4.133-10.774-2.921c0 0-2.666-1.867-2.666-4.788 0-2.92 1.868-6.112 1.868-6.112s-4.39 7.581-8.109 3.192c-.973-1.149 2.985-9.384 2.985-9.384s-3.655 2.202-5.379-1.915c-.527-1.261 5.571-12.592 6.896-13.438 4.469-2.809 16.105-8.81 20.095-6.528 3.99 2.283 5.315 9.464 7.71 12.114 2.394 2.649 1.069 11.698 0 18.081'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M510.992 397.914s4.198-7.23 5.395-8.172c1.197-.941 11.301-3.862 11.301-3.862'
      fill='#fff'
    />
    <path
      d='M510.992 397.914s4.198-7.23 5.395-8.172c1.197-.941 11.301-3.862 11.301-3.862'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M516.116 404.106c2.522-2.793 3.99-6.017 5.06-6.208 1.069-.192 9.178-1.389 9.848-1.644'
      fill='#fff'
    />
    <path
      d='M516.116 404.106c2.522-2.793 3.99-6.017 5.06-6.208 1.069-.192 9.178-1.389 9.848-1.644'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M516.483 414.671c2.825-3.24 7.215-6.575 8.14-6.575.926 0 6.114-.671 6.385-.798'
      fill='#fff'
    />
    <path
      d='M516.483 414.671c2.825-3.24 7.215-6.575 8.14-6.575.926 0 6.114-.671 6.385-.798'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M529.555 416.347s-2.41 2.122-6.001 1.468Z'
      fill='#fff'
    />
    <path
      d='M529.555 416.347s-2.41 2.122-6.001 1.468'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M527.688 417.927s10.535 8.793 15.323-1.596Z'
      fill='#fff'
    />
    <path
      d='M527.688 417.927s10.535 8.793 15.323-1.596'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M269.67 324.533s-4.661-.128-11.413-.304c-15.546-.383-42.218-.957-48.539-.558-11.683.75-23.048 6.144-23.048 6.144l-25.746-35.11c3.927-48.66 34.956-53.991 34.956-53.991s21.915 25.009 29.896 31.823c7.981 6.815 43.894 51.996 43.894 51.996Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.2}
      d='M258.257 324.229c-15.546-.383-42.218-.957-48.539-.558-11.683.75-23.048 6.144-23.048 6.144l-25.746-35.11c3.927-48.66 34.956-53.991 34.956-53.991s1.819 29.717-21.915 50.097c0 0 7.342 20.619 23.367 24.705 16.042 4.085 47.31.734 60.925 8.713Z'
      fill='#000'
    />
    <path
      d='M269.67 324.533s-35.913-45.181-43.894-51.996c-7.981-6.814-29.896-31.823-29.896-31.823s-31.029 5.331-34.972 53.991l25.746 35.11'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M193.837 246.571s-.559 26.11-28.986 40.154l-49.21-69.63c-3.463-18.832 34.318-25.855 34.318-25.855v.032l17.829 22.455 26.049 32.844Z'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M167.788 213.727c-16.616 13.135-35.052-1.037-35.052-1.037l17.238-21.418 17.814 22.455Z'
      fill='#EF9C00'
    />
    <path
      d='M193.837 246.571s-.559 26.11-28.986 40.154l-49.21-69.63c-3.463-18.832 34.318-25.855 34.318-25.855v.032l17.829 22.455 26.049 32.844Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M115.641 217.095c-2.011-4.309 0-14.763 3.576-26.876 4.102-13.9 10.247-30.019 14.764-41.542 2.49-6.4 4.485-11.379 5.331-13.821 3.735-10.645 22.985.527 21.612 11.698-1.389 11.172 2.634 50.544-6.289 60.646-8.938 10.118-17.605 9.895-17.605 9.895'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M146.878 142.261s-7.215 43.075-14.142 45.596c-2.905 1.053-8.268 1.819-13.519 2.362 4.102-13.9 10.247-30.019 14.764-41.542l12.897-6.416Z'
      fill='#EF9C00'
    />
    <path
      d='M115.641 217.095c-2.011-4.309 0-14.763 3.576-26.876 4.102-13.9 10.247-30.019 14.764-41.542 2.49-6.4 4.485-11.379 5.331-13.821 3.735-10.645 22.985.527 21.612 11.698-1.389 11.172 2.634 50.544-6.289 60.646-8.938 10.118-17.605 9.895-17.605 9.895'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M131.14 167.03a4.489 4.489 0 0 1-6.257 1.006l-67.246-48.581a4.472 4.472 0 0 1-1.005-6.256l65.777-91.031a4.474 4.474 0 0 1 6.257-1.006l67.246 48.58a4.486 4.486 0 0 1 1.005 6.256l-65.793 91.032h.016Z'
      fill='#BFBFBF'
      stroke='#263238'
      strokeMiterlimit={10}
    />
    <path
      d='m145.757 31.535-5.843 8.085 34.958 25.255 5.843-8.085-34.958-25.255Z'
      fill='#EF9C00'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m126.286 26.806-64.035 88.611 65.012 46.968 64.034-88.61-65.011-46.969Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.7}
      d='m126.286 26.806-64.035 88.611 65.012 46.968 64.034-88.61-65.011-46.969Z'
      fill='#fff'
    />
    <path
      d='m126.286 26.806-64.035 88.611 65.012 46.968 64.034-88.61-65.011-46.969Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m173.534 57.214-3.544 4.9a1.689 1.689 0 0 1-2.362.383l-8.843-6.4a3.518 3.518 0 0 0-1.261-4.038 3.519 3.519 0 0 0-4.23.08l-8.858-6.4a1.7 1.7 0 0 1-.383-2.362l3.543-4.9a1.704 1.704 0 0 1 2.363-.382l23.192 16.757c.766.559.925 1.612.383 2.362v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M154.747 152.587s10.151-6.32 12.37-9.416c2.219-3.096 8.045-17.092 6.64-21.417-1.404-4.325-6.496-12.066-6.879-15.401-.383-3.336-7.183 2.49-3.32 16.215 0 0-9.226 7.628-9.912 17.794'
      fill='#fff'
    />
    <path
      d='M154.747 152.587s10.151-6.32 12.37-9.416c2.219-3.096 8.045-17.092 6.64-21.417-1.404-4.325-6.496-12.066-6.879-15.401-.383-3.336-7.183 2.49-3.32 16.215 0 0-9.226 7.628-9.912 17.794'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M149.336 93.745c0 12.049-9.768 21.832-21.835 21.832s-21.836-9.767-21.836-21.832c0-12.065 9.769-21.832 21.836-21.832 12.067 0 21.835 9.767 21.835 21.832Z'
      fill='#fff'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m116.631 85.909 2.681 15.321 24.422-6.623'
      fill='#fff'
    />
    <path
      d='m116.631 85.909 2.681 15.321 24.422-6.623'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M261.929 331.89s-31.333 4.995-54.908 17.747c-4.836 2.601-9.338 5.554-13.184 8.841-10.966 9.4-20.543 28.871-28.715 44.303-6.991 13.151-12.274 18.385-12.274 18.385-21.772-30.163-20.08-54.197-20.08-54.197s.08-.048.207-.112c3.544-2.075 52.418-30.435 65.028-34.217 12.944-3.894 63.926-.75 63.926-.75Z'
      fill='#EF9C00'
    />
    <path
      opacity={0.2}
      d='M207.021 349.637c-4.836 2.601-9.338 5.554-13.184 8.841-10.966 9.4-20.543 28.871-28.715 44.303-6.991 13.151-12.274 18.385-12.274 18.385-21.772-30.163-20.08-54.197-20.08-54.197s.08-.048.207-.112c1.676-.75 12.961-4.501 27.949 24.258 0 0 10.359-33.818 46.097-41.478Z'
      fill='#000'
    />
    <path
      d='M101.771 297.386s-13.695-3.559-9.61 9.272c4.103 12.832 18.772 12.417 18.772 12.417'
      fill='#fff'
    />
    <path
      d='M101.771 297.386s-13.695-3.559-9.61 9.272c4.103 12.832 18.772 12.417 18.772 12.417M261.929 331.89s-50.982-3.144-63.926.75c-13.105 3.942-65.251 34.329-65.251 34.329s-1.692 24.034 20.08 54.197'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M154.492 414.511s-75.834 27.466-88.22 12.544c0 0 5.619-15.783 13.248-27.833 4.102-6.479 8.811-11.889 13.536-13.134 6.065-1.612 13.567-4.437 20.542-7.31 11.492-4.755 21.564-9.703 21.564-9.703 19.234 12.688 19.33 45.436 19.33 45.436Z'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M113.598 378.778c-2.171 10.262-8.268 29.398-23.575 28.153-5.938-.479-9.066-3.543-10.503-7.709 4.102-6.479 8.811-11.889 13.536-13.134 6.065-1.612 13.567-4.437 20.542-7.31Z'
      fill='#EF9C00'
    />
    <path
      d='M154.492 414.511s-75.834 27.466-88.22 12.544c0 0 5.619-15.783 13.248-27.833 4.102-6.479 8.811-11.889 13.536-13.134 6.065-1.612 13.567-4.437 20.542-7.31 11.492-4.755 21.564-9.703 21.564-9.703 19.234 12.688 19.33 45.436 19.33 45.436v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M72.098 430.343c-17.845.798-18.978-41.207-10.008-103.592 0 0 13.76-27.306 20.112-27.673 1.708-.096 4.07-.144 6.592-.144 6.847-.048 14.956.144 14.956.144s3.671 15.815 3.703 21.194c.048 8.059-3.496 8.969-3.496 8.969.192 7.596-5.953 8.362-12.545 7.325 0 0-.112.383-.463.958-.878 1.468-3.256 4.197-9.002 4.931-.096 24.338 23.543 61.188 11.029 69.232'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M107.453 320.272c.048 8.059-3.496 8.969-3.496 8.969.192 7.596-5.953 8.362-12.545 7.325 0 0-.112.383-.463.958l2.027-6.113s2.123.894 3.4.271c3.99-1.963 1.9-8.841 1.9-8.841s2.266-.606 2.904-3.75c.623-3.144-6.337-5.267-6.337-5.267 2.187-3.655-2.681-7.15-2.681-7.15 1.74-2.617-.527-5.49-3.368-7.74 6.847-.048 14.956.144 14.956.144s3.671 15.815 3.703 21.194Z'
      fill='#EF9C00'
    />
    <path
      d='M72.098 430.343c-17.845.798-18.978-41.207-10.008-103.592 0 0 13.76-27.306 20.112-27.673 1.708-.096 4.07-.144 6.592-.144 6.847-.048 14.956.144 14.956.144s3.671 15.815 3.703 21.194c.048 8.059-3.496 8.969-3.496 8.969.192 7.596-5.953 8.362-12.545 7.325 0 0-.112.383-.463.958-.878 1.468-3.256 4.197-9.002 4.931-.096 24.338 23.543 61.188 11.029 69.232'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M103.83 317.942c-2.203-4.054-4.326-1.708-14.924-4.262 0 0 6.847.687 12.067-.574M87.677 305.796c1.436.639 7.661.176 10.598 0M91.412 322.905c-.032-.303 8.922.447 10.375 1.5 1.452 1.053 2.362 5.011 2.362 5.011M98.275 331.411c-.67.607-5.3 0-5.3 0s-.302 3.096-1.58 5.171'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M146.878 292.311c0 13.086-5.347 24.481-13.232 30.466h-25.299c-7.901-5.985-13.248-17.38-13.248-30.466 0-.543.048-1.069.064-1.612h51.651c.032.543.064 1.069.064 1.612Z'
      fill='#757575'
    />
    <path
      opacity={0.2}
      d='m127.501 321.277-.112 1.5h-19.042c-7.901-5.985-13.248-17.38-13.248-30.466 0-.543.048-1.069.064-1.612h5.618c3.879 9.544 12.402 25.886 26.72 30.578Z'
      fill='#000'
    />
    <path
      d='M146.878 292.311c0 13.086-5.347 24.481-13.232 30.466h-25.299c-7.901-5.985-13.248-17.38-13.248-30.466 0-.543.048-1.069.064-1.612h51.651c.032.543.064 1.069.064 1.612Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M135.657 321.277h-29.321l-1.373 3.926h32.067l-1.373-3.926Z'
      fill='#757575'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity={0.2}
      d='m137.03 325.203-1.373-3.926h-29.321l-1.373 3.926'
      fill='#000'
    />
    <path
      d='M112.688 258.876c-4.453 7.661 7.486 14.858 1.565 25.328M120.334 258.876c-4.453 7.661 7.486 14.858 1.564 25.328M128.522 258.876c-4.453 7.661 7.486 14.858 1.564 25.328'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M428.168 371.246c-11.349-19.343-58.292-36.196-110.422-38.271-.144 0-.271 0-.415-.016a271.748 271.748 0 0 0-5.14-.143v-.048c-.877 0-1.739 0-2.617.032-.862 0-1.74-.032-2.602-.032v.048c-1.724.032-3.432.079-5.14.143-.143 0-.287 0-.447.016-52.13 2.091-99.057 18.928-110.405 38.271-12.115 20.667-30.072 53.926-30.072 53.926 10.056 16.279 48.683 18.465 48.683 18.465l2.059 43.808h195.832l2.059-43.808s38.627-2.17 48.682-18.465c0 0-17.94-33.259-30.071-53.926h.016Z'
      fill='#EF9C00'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M252.016 471.646s-90.262 15.145-96.758 2.777c-.655-1.245-1.086-2.729-1.309-4.405-2.123-14.922 10.822-44.351 10.822-44.351 10.04-.958 20.574 1.644 29.002 4.724h.016c9.8 3.607 16.759 7.9 16.823 8.091l-.67 1.469-.224.462 21.086.671 18.866.606h.431v.048l1.915 29.908Z'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M209.942 439.951c-20.654.399-28.491 3.239-28.491 3.239s5.219-8.219 12.322-12.799h.016c9.8 3.607 16.759 7.9 16.823 8.091l-.67 1.469ZM252.016 471.646s-90.262 15.145-96.758 2.777c-.655-1.245-1.086-2.729-1.309-4.405 2.314.878 4.677 1.452 6.975 1.532 11.812.415 71.38-6.942 71.38-6.942l-1.5-23.524-.048-.766c5.714.287 12.003.718 18.914 1.372l.431.048 1.915 29.908Z'
      fill='#EF9C00'
    />
    <path
      d='M252.016 471.646s-90.262 15.145-96.758 2.777c-.655-1.245-1.086-2.729-1.309-4.405-2.123-14.922 10.822-44.351 10.822-44.351 10.04-.958 20.574 1.644 29.002 4.724h.016c9.8 3.607 16.759 7.9 16.823 8.091l-.67 1.469-.224.462 21.086.671 18.866.606h.431v.048l1.915 29.908v0Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M209.718 440.413s-21.324-.558-30.406 3.607M401.592 434.365s-8.364-38.638 7.853-57.342'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M415.782 396.254s-10.982 7.948-12.961 20.875M209.718 372.506s14.797 28.871 6.257 63.981'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M198.976 385.88s11.7 7.118 16.983 18.226M345.264 337.54s-17.303 44.159-68.794 20.22M425.486 367.479s-11.157 6.496-16.185 20.859M193.661 367.479s8.811 4.644 13.807 15.816'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M269.67 336.103s5.73 23.524 38.643 24.051M376.883 360.154s-61.18 59.768-104.547 61.938M238.018 370.256s8.476 19.024 23.927 25.998'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M367.594 471.646s90.262 15.145 96.759 2.777c.654-1.245 1.085-2.729 1.308-4.405 2.123-14.922-10.821-44.351-10.821-44.351-10.04-.958-20.575 1.644-29.003 4.724-9.8 3.607-16.759 7.9-16.823 8.091l.67 1.469.224.462-21.085.671-18.867.606h-.431v.048l-1.915 29.908h-.016Z'
      fill='#fff'
    />
    <path
      opacity={0.2}
      d='M409.668 439.951c20.655.399 28.492 3.239 28.492 3.239s-5.22-8.219-12.323-12.799c-9.8 3.607-16.759 7.9-16.823 8.091l.67 1.469h-.016ZM367.594 471.646s90.262 15.145 96.759 2.777c.654-1.245 1.085-2.729 1.308-4.405-2.314.878-4.676 1.452-6.975 1.532-11.811.415-71.38-6.942-71.38-6.942l1.501-23.524.048-.766c-5.715.287-12.003.718-18.915 1.372l-.431.048-1.915 29.908Z'
      fill='#EF9C00'
    />
    <path
      d='M367.594 471.646s90.262 15.145 96.759 2.777c.654-1.245 1.085-2.729 1.308-4.405 2.123-14.922-10.821-44.351-10.821-44.351-10.04-.958-20.575 1.644-29.003 4.724-9.8 3.607-16.759 7.9-16.823 8.091l.67 1.469.224.462-21.085.671-18.867.606h-.431v.048l-1.915 29.908h-.016Z'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M409.892 440.413s21.325-.558 30.407 3.607'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M340.156 337.635s-9.752 17.587-32.434 16.486c-18.563-.925-31.252-15.001-31.252-15.001 6.671-5.411 7.661-18.497 7.198-27.083 0-.192 0-.383-.032-.575a57.114 57.114 0 0 0-.127-1.66c-.511-6.208-1.692-10.884-1.692-10.884l55.562 2.777c-2.395 6.639-3.448 12.193-3.655 16.773-.128 2.873.063 5.362.43 7.517 0 .096.032.16.048.239 1.533 8.267 5.954 11.395 5.954 11.395v.016Z'
      fill='#fff'
      stroke='#263238'
      strokeMiterlimit={10}
    />
    <path
      d='M215.959 464.145v5.937a3.386 3.386 0 0 0 3.384 3.383h178.865a3.385 3.385 0 0 0 3.384-3.383v-5.937H215.959Z'
      fill='#757575'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m423.555 376.799-23.048 86.053c-.479 1.74-1.932 3.256-3.608 3.926-.606.24-1.213.383-1.851.383H222.504c-.623 0-1.245-.143-1.852-.383-1.676-.67-3.128-2.186-3.607-3.926l-23.049-86.053c-.638-2.377.767-4.293 3.145-4.293h223.27c2.378 0 3.782 1.932 3.144 4.293Z'
      fill='#BFBFBF'
      stroke='#263238'
      strokeMiterlimit={10}
    />
    <path
      d='m419.788 381.3-22.905 85.462c-.606.24-1.213.383-1.851.383H222.488c-.623 0-1.245-.143-1.852-.383L197.731 381.3c-.638-2.378.767-4.293 3.145-4.293h215.752c2.378 0 3.783 1.931 3.144 4.293h.016Z'
      fill='#999'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M320.46 429.401c-5.108 0-9.242-3.064-9.242-6.83 0-.878.223-1.692.638-2.458a17.453 17.453 0 0 0-3.064-.287c-7.151 0-12.961 4.293-12.961 9.575 0 5.283 5.794 9.576 12.961 9.576 7.166 0 12.96-4.293 12.96-9.576v-.063c-.415.047-.83.063-1.277.063h-.015Z'
      fill='#BFBFBF'
      stroke='#263238'
      strokeMiterlimit={10}
    />
    <path
      d='M337.379 301.695c-2.395 6.639-3.448 12.193-3.655 16.773-6.034 6.033-13.424 10.533-21.58 9.257-11.796-1.868-21.612-10.581-28.635-17.939-.511-6.208-1.692-10.884-1.692-10.884l55.562 2.777v.016Z'
      fill='#263238'
    />
    <path
      d='M348.695 261.254s4.486-4.868 6.672-3.272c2.203 1.596 4.805 6.129 3.608 10.262-1.197 4.134-6.401 11.347-6.8 15.545-.399 4.197-3.096 8.953-6.049 6.176-2.953-2.777 2.569-28.727 2.569-28.727v.016ZM268.856 261.254s-4.485-4.868-6.688-3.272c-2.203 1.596-4.804 6.129-3.607 10.262 1.197 4.134 6.4 11.347 6.799 15.545.399 4.197 3.097 8.953 6.05 6.176 2.953-2.777-2.57-28.727-2.57-28.727l.016.016Z'
      fill='#fff'
      stroke='#263238'
      strokeMiterlimit={10}
    />
    <path
      d='M307.834 204.981c56.344 0 42.952 75.041 32.577 99.124-3.639 8.458-22.505 19.566-31.635 19.566-8.492 0-27.901-13.262-31.349-21.21-10.486-24.21-23.304-97.48 30.407-97.48Z'
      fill='#fff'
      stroke='#263238'
      strokeMiterlimit={10}
    />
    <path
      d='M317.379 264.845s14.365-2.633 19.202 3.032'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M328.249 275.665c0 1.724-.878 3.128-1.964 3.128-1.085 0-1.963-1.404-1.963-3.128 0-1.723.878-3.128 1.963-3.128 1.086 0 1.964 1.405 1.964 3.128Z'
      fill='#263238'
    />
    <path
      d='M300.172 264.845s-13.248-1.165-19.217 3.032'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M289.303 275.665c0 1.724.877 3.128 1.963 3.128 1.085 0 1.963-1.404 1.963-3.128 0-1.723-.878-3.128-1.963-3.128-1.086 0-1.963 1.405-1.963 3.128Z'
      fill='#263238'
    />
    <path
      d='M331.154 224.165s-20.208 7.596-43.416 0c0 0-7.597-.447-10.598 3.383-3.001 3.83 0 15.624.798 20.428s-6.608 8.139-5.602 20.268l-2.858 4.182s-7.948-19.535-5.953-36.34c1.995-16.805 12.609-23.332 12.609-23.332s4.837-14.507 33.184-14.412c30.567.112 33.647 16.662 33.647 16.662s20.718 13.821 5.395 58.842l-3.32-5.602s1.612-9.065-3.862-17.076c-2.666-3.894 1.596-15.608.59-21.609-1.005-6.001-10.598-5.394-10.598-5.394h-.016Z'
      fill='#263238'
    />
    <path
      d='M312.128 272.537s.846 12.816 3.336 17.683c2.474 4.868-6.209 5.698-9.928 2.809M297.938 303.307c0 1.963 6.448 4.405 12.769 4.405s11.077-2.666 10.136-3.639'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M348.025 232.384s-8.268 11.235-27.039 19.007v-5.346s-16.967 9.783-32.258 8.299l3.464-6.959s-12.881 13.646-23.783 13.119c-10.902-.527-14.062-62.13 40.925-62.13 44.693 0 38.707 34.026 38.707 34.026l-.016-.016Z'
      fill='#263238'
    />
    <path
      d='M338.767 236.102s-2.937 16.295 12.434 28.216c0 0 9.114-9.783 5.603-28.216-3.512-18.433-11.524-19.007-11.524-19.007l-6.497 19.007h-.016Z'
      fill='#263238'
    />
    <path
      opacity={0.2}
      d='M331.92 210.663s-5.73 24.338-26.8 36.706M318.512 210.663s-9.337 23.939-44.054 27.929M340.156 219.616s.144 12.592-8.236 18.976'
      stroke='#fff'
      strokeMiterlimit={10}
    />
    <path
      d='M541.654 31.536 557.504.798M557.504 48.213l35.658-21.401M572.253 73.876l30.949-1.963'
      stroke='#263238'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M64.564 498.202H39.872v-21.21a3.528 3.528 0 0 1 3.527-3.527h530.753a3.528 3.528 0 0 1 3.527 3.527v21.21'
      fill='#fff'
    />
    <path
      d='M64.564 498.202H39.872v-21.21a3.528 3.528 0 0 1 3.527-3.527h530.753a3.528 3.528 0 0 1 3.527 3.527v21.21M548.997 498.202H328.249M312.431 498.202h-15.85M193.837 498.202H77.334'
      stroke='#263238'
      strokeMiterlimit={10}
    />
  </svg>
)
