import { SVGProps } from 'react'

export const InstagramIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.44444 0C3.2657 0 2.13524 0.497518 1.30175 1.38311C0.468253 2.2687 0 3.46981 0 4.72222V12.2778C0 13.5302 0.468253 14.7313 1.30175 15.6169C2.13524 16.5025 3.2657 17 4.44444 17H11.5556C12.7343 17 13.8648 16.5025 14.6983 15.6169C15.5317 14.7313 16 13.5302 16 12.2778V4.72222C16 3.46981 15.5317 2.2687 14.6983 1.38311C13.8648 0.497518 12.7343 0 11.5556 0H4.44444ZM13.3333 1.88889C13.5091 1.88889 13.681 1.94428 13.8272 2.04806C13.9734 2.15183 14.0873 2.29934 14.1546 2.47191C14.2218 2.64448 14.2394 2.83438 14.2051 3.01759C14.1708 3.20079 14.0862 3.36907 13.9619 3.50116C13.8376 3.63324 13.6792 3.72319 13.5067 3.75963C13.3343 3.79607 13.1556 3.77737 12.9932 3.70589C12.8307 3.6344 12.6919 3.51335 12.5942 3.35804C12.4966 3.20273 12.4444 3.02013 12.4444 2.83333C12.4457 2.58328 12.5398 2.34386 12.7062 2.16704C12.8727 1.99022 13.098 1.89027 13.3333 1.88889ZM8 3.77778C8.87903 3.77778 9.73832 4.05473 10.4692 4.57362C11.2001 5.0925 11.7697 5.83001 12.1061 6.69288C12.4425 7.55576 12.5305 8.50524 12.359 9.42126C12.1876 10.3373 11.7643 11.1787 11.1427 11.8391C10.5211 12.4995 9.72921 12.9493 8.86707 13.1315C8.00493 13.3137 7.1113 13.2202 6.29919 12.8628C5.48707 12.5054 4.79294 11.9001 4.30458 11.1235C3.81622 10.347 3.55556 9.43397 3.55556 8.5C3.55556 7.24759 4.02381 6.04647 4.8573 5.16088C5.6908 4.2753 6.82126 3.77778 8 3.77778ZM8 5.66667C7.47258 5.66667 6.95701 5.83284 6.51848 6.14417C6.07995 6.4555 5.73816 6.89801 5.53632 7.41573C5.33449 7.93345 5.28168 8.50314 5.38457 9.05276C5.48747 9.60237 5.74144 10.1072 6.11438 10.5035C6.48732 10.8997 6.96248 11.1696 7.47976 11.2789C7.99704 11.3882 8.53322 11.3321 9.02049 11.1177C9.50776 10.9032 9.92423 10.5401 10.2173 10.0741C10.5103 9.60818 10.6667 9.06038 10.6667 8.5C10.6667 7.74855 10.3857 7.02788 9.88562 6.49653C9.38552 5.96518 8.70724 5.66667 8 5.66667Z'
      fill='#FAFAFA'
    />
  </svg>
)
