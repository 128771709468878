import { useNewWindow } from 'lib/hooks'
import { FC } from 'react'

import { IPartnershipCardData } from '../HomePartnershipsSlider/constants'
import styles from './ClientsPartnerCard.module.css'

export const ClientsPartnerCard: FC<
  IPartnershipCardData
> = ({ description, imageUrl, name, website }) => {
  return (
    <article
      className={styles.cardContainer}
      onClick={() => useNewWindow(website)}
    >
      <div className={styles.cardHeader}>
        <img src={imageUrl} />
      </div>
      <div className={styles.cardBody}>
        <span className={styles.cardTitle}>
          <strong>Name: </strong>
          {name}
        </span>
        <hr />
        <p className={styles.cardDescription}>
          {description}
        </p>
      </div>
    </article>
  )
}
