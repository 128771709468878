import { SVGProps } from 'react'

export const FacebookIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={17}
    height={17}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.222 0H3.778A3.778 3.778 0 0 0 0 3.778v9.444A3.778 3.778 0 0 0 3.778 17h5.309v-6.574H6.87V7.852h2.216V5.958a3.093 3.093 0 0 1 3.3-3.392c.66-.001 1.32.032 1.976.1V4.96h-1.349c-1.066 0-1.274.504-1.274 1.248v1.639h2.55l-.332 2.574h-2.234V17h1.498A3.778 3.778 0 0 0 17 13.222V3.778A3.778 3.778 0 0 0 13.222 0Z'
      fill='#FAFAFA'
    />
  </svg>
)
