import { SVGProps } from 'react'

export const LintrIcon = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={26}
    height={31}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g
      clipPath='url(#a)'
      fill='#00263A'
    >
      <path d='M25.164 15.16h-7.415c.018.216.199.303.32.419 1.502 1.443 3.002 2.889 4.522 4.313.309.288.376.452.025.773a49.316 49.316 0 0 0-2.227 2.19c-.263.271-.414.308-.706.014-2.194-2.201-4.412-4.378-6.606-6.58-.287-.288-.428-.265-.703.01-2.196 2.2-4.414 4.377-6.61 6.576-.284.286-.436.275-.707-.006a59.154 59.154 0 0 0-2.3-2.261c-.298-.276-.234-.417.026-.662 1.516-1.428 3.016-2.873 4.525-4.31.133-.126.29-.227.483-.377-.266-.158-.451-.112-.622-.113-2.143-.004-4.288-.011-6.43.005-.368.003-.5-.079-.491-.469a64.485 64.485 0 0 0 0-3.26c-.01-.395.133-.467.494-.464 2.143.015 4.288.007 6.431.007h.64c-.367-.339-.633-.582-.894-.827-1.374-1.287-2.74-2.58-4.125-3.855-.254-.234-.284-.368-.023-.621a67.249 67.249 0 0 0 2.264-2.297c.288-.307.45-.362.778-.018 1.381 1.443 2.801 2.852 4.202 4.277.127.128.23.28.342.42l.126-.068c.008-.173.023-.346.023-.52.002-2.056.01-4.113-.007-6.169-.003-.363.078-.493.472-.484 1.164.027 2.33.03 3.494-.001.427-.012.491.148.488.516-.015 2.057-.007 4.114-.007 6.17v.58c.384-.236.605-.527.854-.778 1.326-1.334 2.652-2.668 3.96-4.02.245-.253.37-.208.587.017.77.798 1.55 1.587 2.35 2.355.26.252.24.392-.015.629-1.504 1.392-2.993 2.802-4.49 4.202-.133.125-.286.23-.43.344l.06.13h7.343v4.213h-.001ZM14.945 30.813l-.01.098H10.58l.002-.1c.162-.148.366-.159.568-.16 1.076-.006 2.152-.006 3.227 0 .204.002.404.02.57.162h-.002Z' />
      <path d='m14.945 30.813-4.364-.001c-.006-3.137-.01-6.274-.023-9.41-.001-.27.108-.34.36-.338 1.229.009 2.459.009 3.688 0 .254-.002.362.07.36.34-.012 3.137-.016 6.273-.022 9.41l.001-.001Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path
          fill='#fff'
          transform='translate(.247 .803)'
          d='M0 0h24.918v30.109H0z'
        />
      </clipPath>
    </defs>
  </svg>
)
