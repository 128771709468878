import { FC, SVGProps } from 'react'

import * as SVGVariants from './Variants'

type Props = {
  props?: SVGProps<SVGSVGElement>
  variant: SVGVariantsType
}

export type SVGVariantsType =
  | 'header-logo'
  | 'footer-logo'
  | 'social-media'
  | 'cardano-logo'
  | 'instagram-icon'
  | 'facebook-icon'
  | 'twitter-icon'
  | 'youtube-icon'
  | 'discord-icon'
  | 'header-logo-mobile'
  | 'hamburger-menu-icon'
  | 'welcome'
  | 'discord-bepart-card'
  | 'linkedin-icon'
  | 'lintr-icon'
  | 'lintr-footer-icon'

const SVGSelector: Record<SVGVariantsType, any> = {
  'header-logo': SVGVariants.HeaderLogo,
  'footer-logo': SVGVariants.FooterLogo,
  'social-media': SVGVariants.SocialMedia,
  'cardano-logo': SVGVariants.CardanoLogo,
  'instagram-icon': SVGVariants.InstagramIcon,
  'facebook-icon': SVGVariants.FacebookIcon,
  'twitter-icon': SVGVariants.TwitterIcon,
  'youtube-icon': SVGVariants.YoutubeIcon,
  'discord-icon': SVGVariants.DiscordIcon,
  'header-logo-mobile': SVGVariants.HeaderLogoMobile,
  'hamburger-menu-icon': SVGVariants.HamburgerMenuIcon,
  welcome: SVGVariants.Welcome,
  'discord-bepart-card': SVGVariants.DiscordBePartCardIcon,
  'linkedin-icon': SVGVariants.LinkedInIcon,
  'lintr-icon': SVGVariants.LintrIcon,
  'lintr-footer-icon': SVGVariants.LintrFooterIcon
}

export const SVG: FC<Props> = ({ props, variant }) => {
  const SVGSelected = SVGSelector[variant]
  return (
    <>
      <SVGSelected {...props} />
    </>
  )
}
