import { FC, ReactNode } from 'react'

import styles from './HomeRightFigureSection.module.css'

interface Props {
  imageSource: string
  sectionText: string
  sectionTitle: string
  children?: ReactNode
}

export const HomeRightFigureSection: FC<Props> = ({
  imageSource,
  sectionTitle,
  sectionText,
  children
}) => {
  return (
    <section className={styles.sectionContainer}>
      <div className={styles.leftSlot}>
        <div className={styles.leftSlotContent}>
          <div className={styles.leftSlotHeader}>
            <h4>{sectionTitle}</h4>
          </div>
          <div className={styles.leftSlotBody}>
            <p>{sectionText}</p>
          </div>
          <div className={styles.leftSlotFooter}>
            <div className={styles.buttonsContainer}>
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightSlot}>
        <div className={styles.rightSlotContainer}>
          <img
            src={imageSource}
            alt='Section image'
            className={styles.rightSlotImage}
          />
        </div>
      </div>
    </section>
  )
}
