import {
  applicaCorpLogo,
  buenbitLogo,
  endavaLogo,
  globantLogo,
  hpLogo,
  mithrandirtokenLogo,
  otherlabsLogo,
  perficientLogo,
  wesitLogo
} from 'lib/utils/constants/imageUrls'

export interface IPartnershipCardData {
  name: string
  imageUrl: string
  description: string
  website: string
}

export const partnershipsSliderData: IPartnershipCardData[] =
  [
    {
      name: 'Hewlett Packard',
      imageUrl: hpLogo,
      description: `HP is a top tech company that offers PCs, printers, mobile devices, data storage, IT services & professional support. The company's overall goal is to improve the daily lives of individuals and businesses through the power of technology.`,
      website: 'https://www.hp.com'
    },
    {
      name: 'Buenbit Exchange Crypto',
      imageUrl: buenbitLogo,
      website: 'https://www.buenbit.com',
      description: `Buenbit is a cryptocurrency exchange based in the UK. It offers an online platform for buy, sell, and store cryptocurrencies with additional services such as cryptocurrency-backed loans, futures contracts, and reward programs for users.`
    },
    {
      name: 'Endava',
      imageUrl: endavaLogo,
      website: 'https://www.endava.com',
      description: `Endava is a London-based IT services company offering software development, digital transformation and consulting services globally. They help companies grow with innovative tech solutions to banking, insurance, media, healthcare, retail and travel industries.`
    },
    {
      name: 'Otter Labs',
      imageUrl: otherlabsLogo,
      website: 'https://www.hireotter.com',
      description: `Otter Labs are a team of Americans and Argentineans, expert in helping startups hire highly skilled engineers in South America. First, we learn exactly who you need, then we send you candidates with long term contracts.`
    },
    {
      name: 'Globant',
      imageUrl: globantLogo,
      website: 'https://www.globant.com',
      description: `Globant is a multinational technology and consulting services company. They offer digital solutions to companies worldwide, including software development, digital experience design, and strategic consulting. Globant's mission is to help companies transform and grow through the use of technology and innovation.`
    },
    {
      name: 'WES IT',
      imageUrl: wesitLogo,
      website: 'https://www.wes-it.com',
      description: `WES is a digital firm using tech and innovation to optimize client's business processes, attract top talent and provide quality services. Their mission is to improve the world through technology by transforming the way of doing business and living with creativity as the driving force.`
    },
    {
      name: 'Perficient',
      imageUrl: perficientLogo,
      website: 'https://www.perficient.com',
      description: `Perficient is an American tech consulting firm that helps clients improve online presence, modernize systems & apps, and digitally transform with expertise in e-commerce, AI, data analytics, crypto, and emerging technologies. They offer consulting, solutions, and implementation services.`
    },
    {
      name: 'Applica Corp.',
      imageUrl: applicaCorpLogo,
      website: 'https://applicacorp.com/',
      description: `Applica Corp., a Uruguayan software company specializing in custom solutions and staff augmentation, arrived in the US in 2018, working with major companies such as eBay, Cisco, Visa, and Dinners. With plans to expand and reach 350 employees by 2023.`
    },
    {
      name: 'Mithrandir Token',
      imageUrl: mithrandirtokenLogo,
      website: 'https://mithr.io/',
      description: `A platform focused on caring for the earth's ecosystem through cryptocurrency rewards. Supported by Stake Pool Operators, Cardano enthusiasts, and key members of the community as advisors and promoters. Regular meetings and active membership in the DAO.`
    }
  ]
