import { FC, ReactNode } from 'react'

import styles from './B2bRightFigureSection.module.css'

interface Props {
  title: string
  text: string
  image: string
  children: ReactNode
  targetId: string
}

export const B2bRightFigureSection: FC<Props> = ({
  children,
  title,
  image,
  text,
  targetId
}) => {
  return (
    <section
      className={styles.sectionContainer}
      id={targetId}
    >
      <div className={styles.leftSlotContainer}>
        <h3>{title}</h3>
        <div>
          <p>{text}</p>
        </div>
        {children && (
          <div className={styles.childrenContainer}>
            {children}
          </div>
        )}
      </div>
      <div className={styles.rightSlotContainer}>
        <img
          src={image}
          alt=''
        />
      </div>
    </section>
  )
}
