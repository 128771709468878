import { AboutBrandbookForm } from 'view/components'
import { Title } from 'view/elements'

import styles from './AboutBrandbookSection.module.css'

const sectionTitle = `We share the depths of our brand`

export const AboutBrandbookSection = () => {
  return (
    <section className={styles.sectionContainer}>
      <div className={styles.leftSlot}>
        <Title
          dotted={true}
          text={sectionTitle}
        />
      </div>
      <div className={styles.rightSlot}>
        <AboutBrandbookForm />
      </div>
    </section>
  )
}
