import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom'
import {
  AboutUsPage,
  B2bServicePage,
  HomePage,
  OurClientsPage,
  OurCommunityPage
} from 'view/pages'

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path='/about-us'
          element={<AboutUsPage />}
        />
        <Route
          path='/clients'
          element={<OurClientsPage />}
        />
        <Route
          path='/community'
          element={<OurCommunityPage />}
        />

        <Route
          path='/b2b-services'
          element={<B2bServicePage />}
        />
        <Route
          path='/'
          element={<HomePage />}
        />
      </Routes>
    </Router>
  )
}

export default App
