import { FC, ReactNode } from 'react'

import styles from './Title.module.css'

const { smallTitle, mediumTitle, largeTitle, dot } = styles

interface Props {
  text?: string
  dotted?: boolean
  size?: 'small' | 'medium' | 'large'
  children?: ReactNode | ReactNode[]
}

export const Title: FC<Props> = ({
  text,
  dotted,
  size
}) => {
  return (
    <h1
      className={
        size === 'small'
          ? smallTitle
          : size === 'medium'
          ? mediumTitle
          : largeTitle
      }
    >
      {text}
      {dotted ? <span className={dot}> .</span> : null}
    </h1>
  )
}
