import { FC } from 'react'
import {
  FooterNavigationContainer,
  FooterSocialContainer
} from 'view/components'
import { SVG } from 'view/elements'

import styles from './Footer.module.css'

const footerText = `Innovatio is a start-up that facilitates and promotes the adoption of Web3 to individuals, projects and organizations through B2B researching, consulting & development services and a large community of self-taught individuals, entrepreneurs and enthusiasts.`

export const Footer: FC = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.footerContentCenter}>
          <div className={styles.logoContainer}>
            <SVG variant='footer-logo' />
          </div>
          <div className={styles.footerAboutContainer}>
            <h4 className={styles.footerTitle}>About Us</h4>
            <p className={styles.aboutText}>{footerText}</p>
          </div>
          <FooterNavigationContainer />
          <FooterSocialContainer />
        </div>
      </div>
      <div className={styles.footerCopyrightContainer}>
        Copyright © 2023 Innovatio
      </div>
    </footer>
  )
}
