import { ButtonHTMLAttributes, FC, ReactNode } from 'react'

import styles from './Button.module.css'

interface Props
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode | ReactNode[]
  variant: ButtonVariant
  event?: () => void
}

type ButtonVariant =
  | 'primary'
  | 'social'
  | 'default'
  | 'outlined'

const {
  defaultButton,
  socialButton,
  primaryButton,
  outlinedButton
} = styles

const variants = {
  default: defaultButton,
  social: socialButton,
  primary: primaryButton,
  outlined: outlinedButton
}

export const Button: FC<Props> = ({
  children,
  variant,
  event,
  ...props
}) => {
  const selectedClass = variants[variant] || defaultButton

  return (
    <button
      className={selectedClass}
      onClick={event}
      {...props}
    >
      {children}
    </button>
  )
}
