import { useNewWindow } from 'lib/hooks'
import { EXTERNAL_URL_DATA } from 'lib/utils/constants/externalUrls'
import {
  communityBusinessValueFigure,
  communityDiscordFigure,
  communityHeadImage,
  communityMembersFigure,
  communitySocialFigure
} from 'lib/utils/constants/imageUrls'
import { FC, useEffect } from 'react'
import {
  Button,
  PageContentWrapper,
  SVG
} from 'view/elements'
import { MainLayout } from 'view/layouts'
import {
  CommunityLeftFigureSection,
  CommunityRightFigureSection
} from 'view/prototypes'

import styles from './OurCommunityPage.module.css'

const communityDiscordText = `
We are a community of entrepreneurs,
developers and entusiast of the web3 ecosystem,
we are encourage the networking amoung our members to facilitate
the generation and exchange of business value`
const businessText = `We are also a public Web3
community that fosters networking among our members
through the generation and exchange of business value,
 partnerships and collaborations.`
const communityMembersText = `The members of our community are
diverse entrepreneurs, developers, community managers,
marketing specialists, project managers
and all the people who freelance in the Web3 space.`
const socialMediaText = `These are our social networks
where you can view our activities and audiovisual
content such as our Innotalks & Innopodcast.`
export const OurCommunityPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // Hace scroll hasta la parte superior de la página
  }, [])

  return (
    <MainLayout>
      <div className={styles.backgroundImage}>
        <PageContentWrapper>
          <div className={styles.sectionsGap}>
            <div className={styles.welcomeContainer}>
              <div className={styles.imageContainer}>
                <img
                  src={communityHeadImage}
                  className={styles.homeWelcomeImage}
                  alt='Welcome to Innovatio Space'
                />
              </div>
            </div>
            <CommunityLeftFigureSection
              imageSource={communityDiscordFigure}
              sectionText={communityDiscordText}
              sectionTitle={'Discord Community'}
            />

            <CommunityRightFigureSection
              imageSource={communityBusinessValueFigure}
              sectionText={businessText}
              sectionTitle={'Business Value'}
            />
            <CommunityLeftFigureSection
              imageSource={communityMembersFigure}
              sectionText={communityMembersText}
              sectionTitle={'Community Members'}
            >
              <Button
                variant='default'
                onClick={() =>
                  useNewWindow(EXTERNAL_URL_DATA.discord)
                }
              >
                Join Discord
              </Button>
            </CommunityLeftFigureSection>
            <CommunityRightFigureSection
              imageSource={communitySocialFigure}
              sectionText={socialMediaText}
              sectionTitle={'Social Media'}
            >
              <Button
                onClick={() =>
                  useNewWindow(EXTERNAL_URL_DATA.lintktree)
                }
                variant='default'
              >
                <div className={styles.lintrButtonContant}>
                  <SVG variant='lintr-icon' />
                  <span>Lintr.ee</span>
                </div>
              </Button>
            </CommunityRightFigureSection>
          </div>
        </PageContentWrapper>
      </div>
    </MainLayout>
  )
}
