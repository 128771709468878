import { saveAs } from 'file-saver'

export const downloadPdf = (fileUrl: string) => {
  let fileNameResult = ''
  const pdfUrl: string = fileUrl
  const regex = /\/static\/media\/(.*?)\.[^.]*$/
  const match = regex.exec(fileUrl)
  if (match) {
    const fileName = match[1]
    const index = fileName.indexOf('.')
    fileNameResult = fileName.substring(0, index)
  }
  fetch(pdfUrl)
    .then(response => response.blob())
    .then(blob => {
      const pdfBlob: Blob = new Blob([blob], {
        type: 'application/pdf'
      })
      saveAs(pdfBlob, fileNameResult)
    })
}
